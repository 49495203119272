import { memo, useCallback, useState } from 'react'
import tw from 'twin.macro'
import { Button } from 'src/components/shared/Button'
import { SensitiveActionModal } from 'src/components/shared/SensitiveActionModal'
import { PauseCircleMajor } from '@shopify/polaris-icons'

interface Props {
  onDeactivate(): void
}

const DeactivateContractorButtonComponent = ({ onDeactivate }: Props) => {
  const [isConfirmOpen, setIsConfirmOpen] = useState(false)

  const handleOpenConfirm = useCallback(() => {
    setIsConfirmOpen(true)
  }, [])

  const handleCloseConfirm = useCallback(() => {
    setIsConfirmOpen(false)
  }, [])

  return (
    <div css={tw`w-full bg-white`}>
      <Button
        className={tw`px-8`}
        fullWidth
        size="small"
        color="grey"
        onClick={handleOpenConfirm}
        endIcon={<PauseCircleMajor width={20} height={20} />}
      >
        Deactivate contractor
      </Button>
      <SensitiveActionModal
        isOpen={isConfirmOpen}
        title="Deactivate contractor?"
        text="Are you sure you want to reject contractor?"
        submitLabel="Deactivate"
        onClose={handleCloseConfirm}
        onSubmit={onDeactivate}
      />
    </div>
  )
}

export const DeactivateContractor = memo(DeactivateContractorButtonComponent)
