import React, { memo } from 'react'
import tw from 'twin.macro'
import { ProductsMinor } from '@shopify/polaris-icons'
import { Typography, Tooltip } from '@mui/material'
import { cutLongWord } from 'src/utils/baseUtils'

interface Props {
  tag: string
}

const TableTagComponent = ({ tag }: Props) => {
  if (!tag) {
    return null
  }

  const shouldShowTooltip = tag.length > 10

  return (
    <div css={tw`bg-[#EDEDED] rounded flex items-center pl-1 pr-2`}>
      <ProductsMinor width={16} css={tw`m-0.5`} />
      {shouldShowTooltip ? (
        <Tooltip title={tag} arrow>
          <Typography variant="subtitle1">{cutLongWord(tag, 10)}</Typography>
        </Tooltip>
      ) : (
        <Typography variant="subtitle1">{cutLongWord(tag, 10)}</Typography>
      )}
    </div>
  )
}

export const TableTag = memo(TableTagComponent)
