import { FormHelperText, Typography } from '@mui/material'
import { FormControl } from '@mui/material'
import { DatePicker, DatePickerProps } from '@mui/x-date-pickers/DatePicker'
import { Dayjs } from 'dayjs'
import { colors } from 'src/theme'
import tw from 'twin.macro'

interface Props extends DatePickerProps<Dayjs> {
  name?: string
  label?: string
  error?: boolean
  css?: any
  helperText?: string | false
}

export const BasicDatePicker = ({ label, css, error, helperText, ...props }: Props) => {
  return (
    <div css={css}>
      <Typography css={tw`mb-1`} variant="h6" color={colors.GRAY_COOL_GRAY}>
        {label}
      </Typography>
      <FormControl fullWidth error={error}>
        <DatePicker {...props} />
        {helperText ? <FormHelperText>{helperText}</FormHelperText> : null}
      </FormControl>
    </div>
  )
}
