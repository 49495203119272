import { useEffect, useRef, useState } from 'react'

export interface IndicatorStyle {
  left: number
  width: number
}

export const useTabIndicator = <T extends string>(selectedTab: T) => {
  const containerRef = useRef<HTMLDivElement>(null)
  const elementRefs = useRef<Record<T, HTMLSpanElement | null>>(
    Object.create(null) as Record<T, HTMLSpanElement | null>,
  )

  const [indicatorStyle, setIndicatorStyle] = useState<IndicatorStyle>({ left: 0, width: 0 })

  useEffect(() => {
    const container = containerRef.current
    const activeElement = elementRefs.current[selectedTab]

    if (container && activeElement) {
      const containerRect = container.getBoundingClientRect()
      const elementRect = activeElement.getBoundingClientRect()

      setIndicatorStyle({
        left: elementRect.left - containerRect.left,
        width: elementRect.width,
      })
    }
  }, [selectedTab])

  return { containerRef, elementRefs, indicatorStyle }
}
