import { createContext } from 'react'

export interface IOnboardingsContext {
  searchQuery: string
  setSearchQuery(value: string): void

  tagsFilter: Array<string>
  setTagsFilter(statusFilter: Array<string>): void
}

export const OnboardingsContext = createContext<IOnboardingsContext>({
  searchQuery: '',
  setSearchQuery: () => {},

  tagsFilter: [],
  setTagsFilter: () => {},
})
