import React, { memo } from 'react'
import tw from 'twin.macro'
import { useTabIndicator } from 'src/hooks/useTabIndicator'
import { ContractorTab } from './enums'
import { Typography } from '@mui/material'
import { colors } from 'src/theme'

interface Props {
  selectedTab: ContractorTab
  onClick(tab: ContractorTab): void
}

const tabs: { key: ContractorTab; title: string }[] = [
  { key: ContractorTab.Onboarding, title: 'Onboarding steps' },
  { key: ContractorTab.Addons, title: 'Attributes' },
  { key: ContractorTab.Activities, title: 'Activities' },
]

const ContractorDetailsTabsComponent = ({ selectedTab, onClick }: Props) => {
  const { containerRef, elementRefs, indicatorStyle } = useTabIndicator<ContractorTab>(selectedTab)

  return (
    <div
      ref={containerRef}
      css={tw`relative bg-white border-0 border-b border-solid border-[#EDEDED]`}
    >
      <div css={tw`flex w-full overflow-hidden`}>
        {tabs.map((tab) => {
          const isSelected = selectedTab === tab.key

          return (
            <div
              key={tab.key}
              onClick={() => onClick(tab.key)}
              css={tw`pt-4 px-4 pb-3 cursor-pointer transition-colors duration-300`}
            >
              <Typography
                variant={isSelected ? 'h6' : 'subtitle1'}
                ref={(el) => (elementRefs.current[tab.key] = el)}
                color={isSelected ? colors.BASICS_BLACK : colors.SHADES_GREY_500}
              >
                {tab.title}
              </Typography>
            </div>
          )
        })}
      </div>
      <div
        css={[
          tw`absolute bottom-0 h-[3px] bg-black rounded-tl-[8px] rounded-tr-[8px] transition-all duration-300`,
          { left: indicatorStyle.left, width: indicatorStyle.width },
        ]}
      />
    </div>
  )
}

export const ContractorDetailsTabs = memo(ContractorDetailsTabsComponent)
