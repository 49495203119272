import React, { memo, useMemo, ReactNode } from 'react'
import { Modal } from '@mui/material'
import tw from 'twin.macro'
import { Button } from './Button'
import { CircleCancelMinor } from '@shopify/polaris-icons'

interface Props {
  isOpen?: boolean
  content: ReactNode
  fullScreen?: boolean
  onClose(): void
}

const FileModalComponent = ({ content, isOpen, fullScreen, onClose }: Props) => {
  const container = useMemo(() => document.getElementById('root'), [])

  return (
    <Modal
      container={container}
      open={Boolean(isOpen)}
      onClose={onClose}
      aria-labelledby="parent-modal-title"
      aria-describedby="parent-modal-description"
      css={tw`flex justify-center items-center backdrop-blur p-4 z-[13000]`}
    >
      <div
        css={[
          tw`bg-white rounded-lg w-[724px] max-h-[95vh] flex flex-col p-4 gap-2`,
          fullScreen && tw`h-full`,
        ]}
      >
        {content}
        <div css={tw`[transform: translate(-50%, -50%)] bottom-6 left-1/2 absolute`}>
          <Button
            size="small"
            color="black"
            rightIcon={<CircleCancelMinor width={20} fill="white" />}
            onClick={onClose}
          >
            Close file
          </Button>
        </div>
      </div>
    </Modal>
  )
}

export const FileModal = memo(FileModalComponent)
