import React, { useState, useCallback } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import tw from 'twin.macro'
import { ReplayMinor } from '@shopify/polaris-icons'
import { Button } from '../../shared/Button'
import { RejectOnboarding, RecollectOnboardingStep } from 'src/constants/actionTypes'
import { createAsyncAction } from 'src/utils/reduxUtils'
import { showToast } from 'src/utils/toast'
import { handleApiError } from 'src/utils/errorHandlers'
import { SensitiveActionModal } from 'src/components/shared/SensitiveActionModal'
import { CollectedData } from 'src/interfaces/collectedData'
import { CollectedDataStepType } from 'src/constants/collectedData'
import { ContractorDetails } from 'src/interfaces/contractor'
import { getUser } from 'src/selectors/user'

interface Props {
  collectedData: CollectedData
  contractorDetails: ContractorDetails
  onAction(): void | Promise<void>
}

const RecollectButton: React.FC<Props> = ({ collectedData, contractorDetails, onAction }) => {
  const dispatch = useDispatch()
  const user = useSelector(getUser)
  const [isLoading, setIsLoading] = useState(false)
  const [isConfirmOpen, setIsConfirmOpen] = useState(false)

  const openConfirm = useCallback(() => {
    setIsConfirmOpen(true)
  }, [])

  const closeConfirm = useCallback(() => {
    setIsConfirmOpen(false)
  }, [])

  const rejectContractor = useCallback(async () => {
    try {
      await createAsyncAction(
        dispatch,
        RejectOnboarding.request({ onboardingId: contractorDetails.onboarding.id }),
      )
      showToast('Step is rejected.')
      await onAction()
    } catch (err) {
      handleApiError(err)
    }
  }, [contractorDetails])

  const handleRecollect = useCallback(() => {
    openConfirm()
  }, [collectedData])

  const handleRecollectConfirm = useCallback(async () => {
    setIsLoading(true)

    try {
      if (collectedData.collectDataStep.type === CollectedDataStepType.InPersonMeeting) {
        await rejectContractor()
      } else {
        await createAsyncAction(
          dispatch,
          RecollectOnboardingStep.request({
            stepId: collectedData.collectDataStep.id,
            partnerDriverId: contractorDetails.id,
            reviewedByEmail: user?.email,
          }),
        )
        showToast('Step is sent to recollection.')
        await onAction()
      }
    } catch (err) {
      handleApiError(err)
    }

    setIsLoading(false)
    closeConfirm()
  }, [collectedData, contractorDetails, user?.email])

  return (
    <>
      <Button
        onClick={handleRecollect}
        disabled={isLoading}
        fullWidth
        size="small"
        color="grey"
        css={[tw`flex items-center justify-center rounded flex-1`]}
        endIcon={<ReplayMinor width={20} />}
      >
        Recollect
      </Button>
      <SensitiveActionModal
        isOpen={isConfirmOpen}
        title="Recollect step?"
        text="Are you sure you want to initiate recollection?"
        submitLabel="Yes"
        onClose={closeConfirm}
        onSubmit={handleRecollectConfirm}
      />
    </>
  )
}

export default RecollectButton
