import dayjs from 'dayjs'
import * as Yup from 'yup'

Yup.addMethod(Yup.string, 'validateDate', function (errorMessage) {
  return this.test(`test-date`, errorMessage, function (value) {
    const { path, createError } = this

    return dayjs(value).isValid() || createError({ path, message: errorMessage })
  })
})

export const getEditFieldSchema = (label: string, type: 'input' | 'select' | 'date') => {
  let value = Yup.string().required(`${label} is required`)

  if (type === 'date') {
    // @ts-ignore
    value = Yup.string().required(`${label} is required`).validateDate('Invalid date')
  }

  return Yup.object().shape({
    value,
  })
}
