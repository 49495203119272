import React, { useState, useCallback } from 'react'
import { useDispatch } from 'react-redux'
import tw from 'twin.macro'
import { ThumbsUpMinor } from '@shopify/polaris-icons'
import { Button } from '../../shared/Button'
import { colors } from 'src/theme'
import { ApproveOnboardingStep } from 'src/constants/actionTypes'
import { createAsyncAction } from 'src/utils/reduxUtils'
import { showToast } from 'src/utils/toast'
import { handleApiError } from 'src/utils/errorHandlers'
import { CollectedData } from 'src/interfaces/collectedData'
import { ContractorDetails } from 'src/interfaces/contractor'

interface Props {
  collectedData: CollectedData
  contractorDetails: ContractorDetails
  onAction(): void | Promise<void>
}

const ApproveButton: React.FC<Props> = ({ collectedData, contractorDetails, onAction }) => {
  const dispatch = useDispatch()
  const [isLoading, setIsLoading] = useState(false)

  const handleApprove = useCallback(async () => {
    setIsLoading(true)
    try {
      await createAsyncAction(
        dispatch,
        ApproveOnboardingStep.request({
          stepId: collectedData.collectDataStep.id,
          partnerDriverId: contractorDetails.id,
        }),
      )
      await onAction()
      showToast('Step is approved.')
    } catch (err) {
      handleApiError(err)
    }
    setIsLoading(false)
  }, [collectedData, contractorDetails])

  return (
    <Button
      onClick={handleApprove}
      disabled={isLoading}
      fullWidth
      size="small"
      color="green"
      css={[tw`flex items-center justify-center rounded`]}
      endIcon={<ThumbsUpMinor width={20} fill={colors.COLORS_GREEN_500} />}
    >
      Approve
    </Button>
  )
}

export default ApproveButton
