import { useFormikContext } from 'formik'
import { BasicDatePicker } from '../BasicDatePicker'
import dayjs, { Dayjs } from 'dayjs'
import { useCallback, useMemo, useState } from 'react'
import { DatePickerProps, DateValidationError } from '@mui/x-date-pickers'

interface Props<T> extends DatePickerProps<Dayjs> {
  name: keyof T
  label: string
  minDateError?: string
}

export const FormikDatePicker = <T extends unknown>({
  name,
  label,
  minDateError,
  ...props
}: Props<T>) => {
  const { values, touched, errors, setFieldValue } = useFormikContext<T>()

  const [validationError, setError] = useState<DateValidationError | null>(null)

  const isTouched = touched[name] as boolean
  const error = errors[name] as string | undefined

  const onChange = useCallback(
    (date: Dayjs | null) => {
      const now = dayjs()

      setFieldValue(
        name as string,
        date?.isValid()
          ? date
              .hour(now.hour())
              .minute(now.minute())
              .second(now.second())
              .millisecond(now.millisecond())
              .toISOString()
          : null,
      )
    },
    [name, setFieldValue],
  )
  const errorMessage = useMemo(() => {
    switch (validationError) {
      case 'minDate': {
        return minDateError
      }

      case 'invalidDate': {
        return 'Your date is not valid'
      }

      default: {
        return ''
      }
    }
  }, [minDateError, validationError])

  const value = useMemo(
    () => (values[name] ? dayjs(values[name] as string) : undefined),
    [values, name],
  )

  return (
    <BasicDatePicker
      {...props}
      slotProps={{
        textField: {
          helperText: errorMessage,
        },
      }}
      name={name as string}
      value={value}
      error={Boolean(isTouched && error)}
      helperText={isTouched && error}
      label={label}
      onChange={onChange}
      onError={(newError) => setError(newError)}
    />
  )
}
