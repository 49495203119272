import { createContext } from 'react'

export interface IContractorsContext {
  searchQuery: string
  setSearchQuery(value: string): void
}

export const ContractorsContext = createContext<IContractorsContext>({
  searchQuery: '',
  setSearchQuery: () => {},
})
