import { Typography } from '@mui/material'
import dayjs from 'dayjs'
import { ReactNode } from 'react'
import { colors } from 'src/theme'
import { TitledTooltip } from '../shared/TitledTooltip'
import React from 'react'
import { ContractorDeactivationReason } from 'src/constants/contractor/enums'
import { addS } from 'src/utils/baseUtils'
import { BulletList } from '../shared/BulletList'
import tw from 'twin.macro'
import { getOnboardingTableStepTitle } from 'src/utils/collectedData'

interface Props {
  deactivationReason: ContractorDeactivationReason
  deactivatedByEmail?: string | null
  deactivatedAt?: string | null
  expiredSteps?: string[]
  failedSteps?: string[]
  children: ReactNode
}

export const ContractorDeactivationReasonTooltip = ({
  deactivationReason,
  deactivatedByEmail,
  deactivatedAt,
  expiredSteps = [],
  failedSteps = [],
  children,
}: Props) => {
  if (deactivationReason === ContractorDeactivationReason.ExpiredDocuments) {
    return (
      <TitledTooltip
        title="Contractor is deactivated"
        content={
          <>
            <Typography variant="body2" color={colors.SHADES_GREY_300}>
              {`Deactivated ${
                deactivatedAt ? `at ${dayjs(deactivatedAt).format('MMM D, h:mm A')} ` : ''
              }automatically due to the expired ${addS(expiredSteps?.length, 'step')}`}
            </Typography>
            {!!expiredSteps.length && (
              <div css={tw`mt-2`}>
                <BulletList
                  variant="body2"
                  color={colors.SHADES_GREY_300}
                  texts={expiredSteps.map(getOnboardingTableStepTitle)}
                />
              </div>
            )}
          </>
        }
      >
        {children}
      </TitledTooltip>
    )
  }

  if (deactivationReason === ContractorDeactivationReason.FailedDocument) {
    return (
      <TitledTooltip
        title="Contractor is deactivated"
        content={
          <>
            <Typography variant="body2" color={colors.SHADES_GREY_300}>
              {`Deactivated ${
                deactivatedAt ? `at ${dayjs(deactivatedAt).format('MMM D, h:mm A')} ` : ''
              }automatically due to the failed ${addS(failedSteps?.length, 'step')}`}
            </Typography>
            {!!failedSteps.length && (
              <div css={tw`mt-2`}>
                <BulletList
                  variant="body2"
                  color={colors.SHADES_GREY_300}
                  texts={failedSteps.map(getOnboardingTableStepTitle)}
                />
              </div>
            )}
          </>
        }
      >
        {children}
      </TitledTooltip>
    )
  }

  if (deactivationReason === ContractorDeactivationReason.Manual) {
    return (
      <TitledTooltip
        title="Contractor is deactivated"
        content={
          <>
            <Typography variant="body2" color={colors.SHADES_GREY_300}>
              {`Deactivated ${
                deactivatedAt ? `at ${dayjs(deactivatedAt).format('MMM D, h:mm A')} ` : ''
              }due to manual deactivation by ${
                deactivatedByEmail ? 'the customer:' : 'a customer'
              }`}
            </Typography>
            {!!deactivatedByEmail && (
              <div css={tw`mt-2`}>
                <BulletList
                  variant="body2"
                  color={colors.SHADES_GREY_300}
                  texts={[deactivatedByEmail]}
                />
              </div>
            )}
          </>
        }
      >
        {children}
      </TitledTooltip>
    )
  }

  if (deactivationReason === ContractorDeactivationReason.GracePeriod) {
    return (
      <TitledTooltip
        title="Contractor is deactivated"
        content={
          <>
            <Typography variant="body2" color={colors.SHADES_GREY_300}>
              {`Deactivated ${
                deactivatedAt ? `at ${dayjs(deactivatedAt).format('MMM D, h:mm A')} ` : ''
              }due to a new required step not being completed within the grace period`}
            </Typography>
          </>
        }
      >
        {children}
      </TitledTooltip>
    )
  }

  return (
    <TitledTooltip
      title="Contractor is deactivated"
      content={
        <>
          <Typography variant="body2" color={colors.SHADES_GREY_300}>
            {`Deactivated ${
              deactivatedAt ? `at ${dayjs(deactivatedAt).format('MMM D, h:mm A')} ` : ''
            }due to automatic deactivation`}
          </Typography>
        </>
      }
    >
      {children}
    </TitledTooltip>
  )
}
