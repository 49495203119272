import { Typography } from '@mui/material'
import dayjs from 'dayjs'
import { ReactNode, useMemo } from 'react'
import { Payment } from 'src/interfaces/payment'
import { colors } from 'src/theme'
import { TitledTooltip } from '../shared/TitledTooltip'
import { NumericList } from '../shared/NumericList'
import React from 'react'
import { ContractorDeactivationReason } from 'src/constants/contractor/enums'
import { PaymentStatus, SingleFailureReasonType } from 'src/constants/payment/enums'
import tw from 'twin.macro'
import { addS } from 'src/utils/baseUtils'
import { failureReasonTitles } from 'src/constants/payment/constants'

interface Props {
  payment: Payment
  children: ReactNode
}

const needsActionFailedReasons = [
  SingleFailureReasonType.UnsuccessfulPaymentAttempt,
  SingleFailureReasonType.NoValidPaymentMethod,
]

const getTooltipContent = (payment: Payment) => {
  if (payment.failureReasonType === SingleFailureReasonType.BadPayment && payment.failureReason) {
    return {
      title: failureReasonTitles[SingleFailureReasonType.BadPayment],
      content: (
        <>
          <Typography css={tw`mb-2`} variant="body2" color={colors.SHADES_GREY_300}>
            This payment needs to be re-uploaded by the customer when these issues are resolved:
          </Typography>
          <NumericList
            variant="body2"
            color={colors.SHADES_GREY_300}
            texts={[payment.failureReason]}
          />
        </>
      ),
    }
  }

  if (payment.failureReasonType === SingleFailureReasonType.ContractorNotOnboarded) {
    return {
      title: failureReasonTitles[SingleFailureReasonType.ContractorNotOnboarded],
      content: (
        <>
          <Typography variant="body2" color={colors.SHADES_GREY_300}>
            This payment needs to be re-uploaded by the customer when the contractor finishes their
            onboarding
          </Typography>
        </>
      ),
    }
  }

  if (payment.failureReasonType === SingleFailureReasonType.ContractorDeactivated) {
    return {
      title: failureReasonTitles[SingleFailureReasonType.ContractorDeactivated],
      content: (
        <>
          {!!payment.deactivatedAt && (
            <Typography variant="body2" css={tw`mb-2`} color={colors.SHADES_GREY_300}>
              {`Deactivated at ${dayjs(payment.deactivatedAt).format('MMM D, h:mm A')} due to ${
                payment.deactivationReason === ContractorDeactivationReason.Manual
                  ? 'manual'
                  : 'automatic'
              } deactivation${
                payment.deactivationReason === ContractorDeactivationReason.Manual
                  ? ' by the customer'
                  : ''
              }`}
            </Typography>
          )}
          <NumericList
            variant="body2"
            color={colors.SHADES_GREY_300}
            texts={[
              payment.deactivationReason === ContractorDeactivationReason.Manual
                ? 'Contractor needs to be re-activated by the customer before they can receive payments'
                : 'Contractor needs to update their information before they can be re-activated. Contractor already received the necessary communication to do it.',
              "This payment can only be retried once the contractor's status is active again. To do so, click the Retry button located on the right side.",
            ]}
          />
        </>
      ),
    }
  }

  if (payment.failureReasonType === SingleFailureReasonType.NoValidPaymentMethod) {
    return {
      title: failureReasonTitles[SingleFailureReasonType.NoValidPaymentMethod],
      content: (
        <NumericList
          variant="body2"
          color={colors.SHADES_GREY_300}
          texts={[
            'This payment needs to be re-uploaded by the customer when the contractor finishes adding their payment method',
            'Customer can re-send an invite to help the contractor locate page to update their payment method',
          ]}
        />
      ),
    }
  }

  if (payment.failureReasonType === SingleFailureReasonType.UnsuccessfulPaymentAttempt) {
    return {
      title: failureReasonTitles[SingleFailureReasonType.UnsuccessfulPaymentAttempt],
      content: (
        <NumericList
          variant="body2"
          color={colors.SHADES_GREY_300}
          texts={[
            'The contractor already received communications with instructions on how to update their payment method',
            'This payment needs to be re-uploaded by the customer when the contractor finishes updating their payment method',
          ]}
        />
      ),
    }
  }

  return {
    title: failureReasonTitles[SingleFailureReasonType.Unknown],
    content: (
      <>
        <Typography variant="body2" color={colors.SHADES_GREY_300}>
          Please contact support
        </Typography>
      </>
    ),
  }
}

export const PaymentFailureReasonTooltip = ({ payment, children }: Props) => {
  const { title, content } = getTooltipContent(payment)

  const daysLeft = useMemo(
    () =>
      payment.failedAt
        ? 7 - dayjs().startOf('day').diff(dayjs(payment.failedAt).startOf('day'), 'day')
        : 7,
    [payment.failedAt],
  )

  return (
    <TitledTooltip
      title={payment.status === PaymentStatus.AutoRetry ? 'Automated payment retry' : title}
      content={
        <>
          {payment.status === PaymentStatus.AutoRetry && (
            <>
              <Typography variant="body2" color={colors.SHADES_GREY_300}>
                {payment.failureReasonType &&
                needsActionFailedReasons.includes(payment.failureReasonType)
                  ? `Contractor has ${daysLeft} ${addS(
                      daysLeft,
                      'day',
                    )} to update their payment method after which payment will be retried automatically, otherwise payment will fail.`
                  : 'The payment will be automatically retried up to 5 times. After 5 failed attempts, it will be marked as "Failed”.'}
              </Typography>
              <div css={tw`mt-4 mb-1`}>
                <Typography variant="overline" color={colors.SHADES_GREY_500} css={tw`uppercase`}>
                  Failure reason
                </Typography>
              </div>
              <Typography css={tw`mb-1`} variant="h6" color={colors.SHADES_GREY_200}>
                {title}
              </Typography>
            </>
          )}
          {content}
        </>
      }
    >
      {children}
    </TitledTooltip>
  )
}
