import { createContext, MouseEvent } from 'react'
import { Onboarding } from 'src/interfaces/onboarding'

export interface IOnboardingsTableContext {
  refreshOnboardings(): void

  // edit action
  actionAnchor: null | HTMLElement
  onboardingToAction: Onboarding | null
  openOnboardingsItemAction(event: MouseEvent<HTMLButtonElement>, onboarding: Onboarding): void
  closeOnboardingsItemAction(): void
}

export const OnboardingsTableContext = createContext<IOnboardingsTableContext>({
  refreshOnboardings: () => {},

  actionAnchor: null,
  onboardingToAction: null,
  openOnboardingsItemAction: () => {},
  closeOnboardingsItemAction: () => {},
})
