import React, { memo } from 'react'
import tw from 'twin.macro'
import { Button } from '../shared/Button'
import { CircleChevronRightMinor } from '@shopify/polaris-icons'
import RecollectButton from './step-actions/RecollectButton'
import SetExpirationButton from './step-actions/SetExpirationButton'
import { CollectedData } from 'src/interfaces/collectedData'
import { CollectedDataStatus, CollectedDataApprovalType } from 'src/constants/collectedData'
import { ContractorDetails } from 'src/interfaces/contractor'
import { OnboardingStatus } from 'src/constants/onboarding/enums'
import { colors } from 'src/theme'
import ApproveButton from './step-actions/ApproveButton'
import DeclineButton from './step-actions/DeclineButton'

interface Props {
  collectedData: CollectedData
  contractorDetails: ContractorDetails
  recollecting?: boolean
  stepCanBeRecollected?: boolean
  showDetails?: boolean
  onAction(): void | Promise<void>
  toggleStepDetailsModal: () => void
}

const ContractorStepActionsButtonComponent: React.FC<Props> = ({
  collectedData,
  contractorDetails,
  recollecting,
  stepCanBeRecollected,
  showDetails,
  onAction,
  toggleStepDetailsModal,
}) => {
  if (recollecting && !showDetails) {
    return null
  }

  return (
    <div css={tw`flex flex-col gap-2 items-center justify-start`}>
      {!showDetails &&
        collectedData.status !== CollectedDataStatus.Completed &&
        contractorDetails.onboarding.status !== OnboardingStatus.Completed && (
          <div css={tw`grid grid-cols-2 gap-2 w-full`}>
            <ApproveButton
              collectedData={collectedData}
              contractorDetails={contractorDetails}
              onAction={onAction}
            />
            <DeclineButton
              collectedData={collectedData}
              contractorDetails={contractorDetails}
              onAction={onAction}
            />
          </div>
        )}

      {!recollecting && (
        <div css={tw`flex gap-2 w-full`}>
          {(contractorDetails.onboarding.status === OnboardingStatus.Completed ||
            stepCanBeRecollected) && (
            <RecollectButton
              collectedData={collectedData}
              contractorDetails={contractorDetails}
              onAction={onAction}
            />
          )}
          {collectedData.status === CollectedDataStatus.Completed &&
            collectedData.collectDataStep.approvalType ===
              CollectedDataApprovalType.CustomerApproval && (
              <SetExpirationButton
                collectedData={collectedData}
                contractorDetails={contractorDetails}
                onAction={onAction}
              />
            )}
        </div>
      )}

      {showDetails && (
        <Button
          onClick={toggleStepDetailsModal}
          fullWidth
          size="small"
          color={
            collectedData.status === CollectedDataStatus.WaitingForApproval ? 'light' : 'white'
          }
          css={[tw`flex items-center justify-center rounded`]}
          endIcon={
            <CircleChevronRightMinor
              width={20}
              fill={
                collectedData.status === CollectedDataStatus.WaitingForApproval
                  ? colors.PRIMARY_PURPLE_500
                  : undefined
              }
            />
          }
        >
          {collectedData.status === CollectedDataStatus.WaitingForApproval
            ? 'Open for review'
            : 'Show details'}
        </Button>
      )}
    </div>
  )
}

export const ContractorStepActionsButton = memo(ContractorStepActionsButtonComponent)
