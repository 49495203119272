import React, { memo, useMemo, useCallback, useState } from 'react'
import tw from 'twin.macro'
import { useDispatch } from 'react-redux'
import { showToast } from 'src/utils/toast'
import { createAsyncAction } from 'src/utils/reduxUtils'
import { handleApiError } from 'src/utils/errorHandlers'
import {
  ActivatePartnerDriver,
  DeactivatePartnerDriver,
  RejectOnboarding,
  UnrejectOnboarding,
  SendRemainderContractor,
  SendRemainderOnboarding,
} from 'src/constants/actionTypes'
import { ContractorDetails } from 'src/interfaces/contractor'
import { OnboardingStatus } from 'src/constants/onboarding/enums'
import { Button } from 'src/components/shared/Button'
import { RejectContractor } from './RejectContractor'
import { UnrejectContractor } from './UnrejectContractor'
import { ActivateContractor } from './ActivateContractor'
import { DeactivateContractor } from './DeactivateContractor'
import { SendMajor } from '@shopify/polaris-icons'
import { colors } from 'src/theme'
import { ContractorDeactivationReason } from 'src/constants/contractor/enums'

interface ContractorActionsProps {
  contractorDetails: ContractorDetails
  flow: 'onboardings' | 'contractors'
  onRefresh(): Promise<void>
  onClose(): void
}

const ContractorActions = memo(
  ({ contractorDetails, flow, onRefresh, onClose }: ContractorActionsProps) => {
    const dispatch = useDispatch()
    const [isLoading, setIsLoading] = useState(false)

    const hasStepsWithRecollectingStatus = contractorDetails.collectedData.some(
      (item) => !!item.isRecollecting,
    )

    const handleRejectWorker = useCallback(async () => {
      setIsLoading(true)
      try {
        await createAsyncAction(
          dispatch,
          RejectOnboarding.request({ onboardingId: contractorDetails.onboarding.id }),
        )
        showToast('Contractor rejected successfully!')
        await onRefresh()

        onClose()
      } catch (err) {
        handleApiError(err)
        showToast('Failed to reject contractor!')
      } finally {
        setIsLoading(false)
      }
    }, [contractorDetails, onRefresh])

    const handleUnrejectWorker = useCallback(async () => {
      try {
        await createAsyncAction(
          dispatch,
          UnrejectOnboarding.request({ onboardingId: contractorDetails.onboarding.id }),
        )
        showToast('Contractor unrejected!')
        await onRefresh()
        onClose()
      } catch (err) {
        handleApiError(err)
      }
    }, [contractorDetails, onRefresh])

    const handleActivateWorker = useCallback(async () => {
      try {
        await createAsyncAction(
          dispatch,
          ActivatePartnerDriver.request({
            partnerDriverId: contractorDetails.id,
          }),
        )
        showToast('Contractor activated!')
        onRefresh()
      } catch (err) {
        handleApiError(err)
      }
    }, [contractorDetails, onRefresh])

    const handleDeactivateWorker = useCallback(async () => {
      try {
        await createAsyncAction(
          dispatch,
          DeactivatePartnerDriver.request({
            partnerDriverId: contractorDetails.id,
          }),
        )
        showToast('Contractor deactivated!')
        onRefresh()
      } catch (err) {
        handleApiError(err)
      }
    }, [contractorDetails, onRefresh])

    const handleResendInvite = useCallback(async () => {
      setIsLoading(true)

      try {
        if (
          contractorDetails.onboarding.status === OnboardingStatus.Created ||
          contractorDetails.onboarding.status === OnboardingStatus.PartiallyCompleted
        ) {
          await createAsyncAction(
            dispatch,
            SendRemainderOnboarding.request({
              partnerDriverId: contractorDetails.id,
            }),
          )
        } else if (hasStepsWithRecollectingStatus && flow === 'contractors') {
          await createAsyncAction(
            dispatch,
            SendRemainderContractor.request({
              partnerDriverId: contractorDetails.id,
            }),
          )
        }
        showToast(
          "The reminder has been resent to the contractor's email address and phone number!",
        )
      } catch (err) {
        handleApiError(err)
        showToast('Failed to resend the reminder.')
      } finally {
        setIsLoading(false)
      }
    }, [contractorDetails, flow])

    const mainActionButton = useMemo(() => {
      if (contractorDetails.onboarding.status === OnboardingStatus.Failed) {
        return <UnrejectContractor onUnreject={handleUnrejectWorker} />
      } else if (flow === 'onboardings') {
        if (contractorDetails.onboarding.status !== OnboardingStatus.Completed) {
          return <RejectContractor onReject={handleRejectWorker} isLoading={isLoading} />
        }
      } else {
        if (contractorDetails.isActive) {
          return <DeactivateContractor onDeactivate={handleDeactivateWorker} />
        } else {
          return <ActivateContractor onActivate={handleActivateWorker} />
        }
      }

      return null
    }, [contractorDetails.onboarding.status, contractorDetails.isActive, flow])

    return (
      <div css={tw`flex flex-col p-4 pb-4`}>
        <div css={tw`w-full h-px bg-[#EDEDED] mb-4`} />
        {contractorDetails.onboarding.status === OnboardingStatus.Created ||
        contractorDetails.onboarding.status === OnboardingStatus.PartiallyCompleted ||
        (contractorDetails.deactivationReason !== ContractorDeactivationReason.Manual &&
          hasStepsWithRecollectingStatus &&
          flow === 'contractors') ? (
          <>
            <Button
              endIcon={
                isLoading ? null : (
                  <SendMajor fill={colors.PRIMARY_PURPLE_500} width={20} height={20} />
                )
              }
              css={tw`w-full h-[36px] flex gap-0`}
              color="light"
              size="small"
              onClick={handleResendInvite}
              loading={isLoading}
            >
              Resend an invite
            </Button>
            <div css={tw`mb-2`} />
          </>
        ) : null}
        {mainActionButton}
      </div>
    )
  },
)

export { ContractorActions }
