import { Info } from '@mui/icons-material'
import { Typography } from '@mui/material'
import { ChevronRightMinor } from '@shopify/polaris-icons'
import { colors } from 'src/theme'
import tw from 'twin.macro'

export interface InfoContainerProps {
  children?: React.ReactNode
  className?: string
  onClick?(): void
}

export const InfoContainer = ({ children, className, onClick }: InfoContainerProps) => {
  return (
    <div
      css={
        onClick
          ? tw`bg-[rgba(242, 229, 255, 1)] hover:bg-[rgba(226, 208, 244, 1)] p-4 py-3 rounded-2xl flex flex-row items-center cursor-pointer`
          : tw`bg-[rgba(242, 229, 255, 1)] p-4 py-3 rounded-2xl flex flex-row items-center`
      }
      className={className}
      onClick={onClick}
    >
      <Info css={tw`mr-4`} color="primary" />
      <div css={tw`flex flex-col`}>
        <Typography variant="body2" color={colors.PRIMARY_PURPLE_500}>
          {children}
        </Typography>
      </div>
      {onClick ? (
        <div css={tw`ml-[16px] h-[20px]`}>
          <ChevronRightMinor width={20} fill={colors.PRIMARY_PURPLE_500} />
        </div>
      ) : null}
    </div>
  )
}
