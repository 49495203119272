import React, { useState, useCallback } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import tw from 'twin.macro'
import { ThumbsDownMinor } from '@shopify/polaris-icons'
import { Button } from '../../shared/Button'
import { colors } from 'src/theme'
import { RejectOnboarding, RecollectOnboardingStep } from 'src/constants/actionTypes'
import { createAsyncAction } from 'src/utils/reduxUtils'
import { showToast } from 'src/utils/toast'
import { handleApiError } from 'src/utils/errorHandlers'
import { SensitiveActionModal } from 'src/components/shared/SensitiveActionModal'
import { CollectedData } from 'src/interfaces/collectedData'
import { CollectedDataStepType } from 'src/constants/collectedData'
import { ContractorDetails } from 'src/interfaces/contractor'
import { getCollectDataStepTitle } from 'src/utils/collectedData'
import { getUser } from 'src/selectors/user'

interface Props {
  collectedData: CollectedData
  contractorDetails: ContractorDetails
  onAction(): void | Promise<void>
}

const DeclineButton: React.FC<Props> = ({ collectedData, contractorDetails, onAction }) => {
  const dispatch = useDispatch()
  const user = useSelector(getUser)
  const [isLoading, setIsLoading] = useState(false)
  const [isConfirmOpen, setIsConfirmOpen] = useState(false)

  const openConfirm = useCallback(() => {
    setIsConfirmOpen(true)
  }, [])

  const closeConfirm = useCallback(() => {
    setIsConfirmOpen(false)
  }, [])

  const rejectContractor = useCallback(async () => {
    try {
      await createAsyncAction(
        dispatch,
        RejectOnboarding.request({ onboardingId: contractorDetails.onboarding.id }),
      )
      await onAction()
      showToast('Step is rejected.')
    } catch (err) {
      handleApiError(err)
    }
  }, [dispatch, contractorDetails, onAction])

  const handleDecline = useCallback(() => {
    if (collectedData.collectDataStep.type === CollectedDataStepType.InPersonMeeting) {
      rejectContractor()
    } else {
      openConfirm()
    }
  }, [collectedData, rejectContractor, openConfirm])

  const handleRecollectConfirm = useCallback(async () => {
    setIsLoading(true)

    try {
      await createAsyncAction(
        dispatch,
        RecollectOnboardingStep.request({
          stepId: collectedData.collectDataStep.id,
          partnerDriverId: contractorDetails.id,
          reviewedByEmail: user?.email,
        }),
      )
      await onAction()
      showToast('Step is sent to recollection.')
    } catch (err) {
      handleApiError(err)
    }
    setIsLoading(false)

    closeConfirm()
  }, [collectedData, contractorDetails, user?.email])

  return (
    <>
      <Button
        onClick={handleDecline}
        disabled={isLoading}
        fullWidth
        size="small"
        color="red"
        css={[tw`flex items-center justify-center rounded`]}
        endIcon={<ThumbsDownMinor width={20} fill={colors.COLORS_RED_500} />}
      >
        Decline
      </Button>
      <SensitiveActionModal
        isOpen={isConfirmOpen}
        title="Recollect step?"
        text={`Are you sure you want to initiate ${getCollectDataStepTitle(
          collectedData,
        )} recollection?`}
        submitLabel="Yes"
        onClose={closeConfirm}
        onSubmit={handleRecollectConfirm}
      />
    </>
  )
}

export default DeclineButton
