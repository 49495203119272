import React, { memo, useMemo, useEffect, useState } from 'react'
import tw from 'twin.macro'
import { Typography, CircularProgress } from '@mui/material'
import { useDispatch, useSelector } from 'react-redux'
import { FetchContractorActivitiesLogs } from 'src/constants/actionTypes'
import EmptyImage from 'src/assets/images/empty-clipboard.svg'
import { ActivityLogItem } from '../activity-log-item/ActivityLogItem'
import { createAsyncAction } from 'src/utils/reduxUtils'
import { handleApiError } from 'src/utils/errorHandlers'
import { getContractorActivitiesLogs } from 'src/selectors/contractor'
import { colors } from 'src/theme'

interface Props {
  contractorId: string
}

const ActivitiesTabComponent = ({ contractorId }: Props) => {
  const [isLoading, setIsLoading] = useState<boolean>(false)
  const [isDataFetched, setIsDataFetched] = useState<boolean>(false)
  const dispatch = useDispatch()

  const activitiesLogs = useSelector(getContractorActivitiesLogs)

  const fetchActivitiesLogs = async () => {
    if (!contractorId) {
      return
    }

    setIsLoading(true)
    setIsDataFetched(false)

    try {
      await createAsyncAction(
        dispatch,
        FetchContractorActivitiesLogs.request({ partnerDriverId: contractorId }),
      )
    } catch (err: unknown) {
      handleApiError(err)
    }
    setIsLoading(false)
    setIsDataFetched(true)
  }

  useEffect(() => {
    if (!contractorId) {
      return
    }

    fetchActivitiesLogs()
  }, [contractorId])

  const content = useMemo(() => {
    if (isLoading || !isDataFetched) {
      return (
        <div css={tw`bg-white rounded-2xl px-4 py-3 h-[275px] flex items-center justify-center`}>
          <CircularProgress />
        </div>
      )
    }

    if (activitiesLogs && activitiesLogs.length > 0) {
      return (
        <div css={tw`bg-white rounded-2xl px-4 py-3 overflow-hidden`}>
          {activitiesLogs.map((activityLog) => (
            <ActivityLogItem key={activityLog.id} activityLog={activityLog} />
          ))}
        </div>
      )
    }

    return (
      <div
        css={tw`bg-white rounded-2xl px-6 py-4 flex flex-col items-center h-[275px] justify-center`}
      >
        <img src={EmptyImage} alt="empty activity log" css={tw`w-[210px] mb-4`} />
        <Typography variant="h4" color="black" css={tw`mb-0.5`}>
          No activity yet.
        </Typography>
        <Typography
          textAlign="center"
          variant="body2"
          color={colors.GRAY_COOL_GRAY}
          css={tw`w-[270px]`}
        >
          Once actions occur, they'll be logged here for your review.
        </Typography>
      </div>
    )
  }, [isLoading, isDataFetched, activitiesLogs])

  return (
    <div css={tw`flex flex-1 flex-col overflow-y-scroll h-full bg-[rgba(246, 246, 246, 1)]`}>
      <div css={tw`p-4 overflow-y-scroll pb-20`}>{content}</div>
    </div>
  )
}

export const ActivitiesTab = memo(ActivitiesTabComponent)
