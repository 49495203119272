import { memo, useMemo } from 'react'
import { Chip } from '../shared/Chip'
import { colors } from 'src/theme'
import tw from 'twin.macro'

interface Props {
  cxtSynced?: boolean
}

const ContractorCXTStatusChipComponent = ({ cxtSynced }: Props) => {
  const params = useMemo(
    () =>
      cxtSynced
        ? {
            color: colors.COLORS_GREEN_500,
            text: 'Synced',
            bgStyle: tw`bg-[rgba(230, 249, 233, 1)]`,
          }
        : {
            color: colors.COLORS_RED_500,
            text: 'Not synced',
            bgStyle: tw`bg-[rgba(255, 232, 238, 1)]`,
          },
    [cxtSynced],
  )

  return <Chip className={params.bgStyle} textColor={params.color} text={params.text} />
}

export const ContractorCXTStatusChip = memo(ContractorCXTStatusChipComponent)
