export const processPaymentCsvErrors = (csvErrors: Array<string>): Array<string> => {
  const result: Array<string> = []
  const errorLinesIndexes: Array<number> = []

  csvErrors.forEach((error, index) => {
    if (error.includes('Error at line')) {
      errorLinesIndexes.push(index)
    }
  })

  for (let idx = 0; idx < errorLinesIndexes.length; idx += 1) {
    const currentLineIndex = errorLinesIndexes[idx]

    if (idx === errorLinesIndexes.length - 1) {
      result.push(csvErrors.slice(currentLineIndex).join('. '))
      continue
    }

    const nextLineIndex = errorLinesIndexes[idx + 1]
    result.push(csvErrors.slice(currentLineIndex, nextLineIndex).join('. '))
  }

  return result
}

interface OnboardingCSVError {
  'Contractors ID': string
  'Contractors name': string
  'Phone number': string
  'Tag(s)': string
  'Documents to review': string
  'Invited at': string
  error: string | string[]
}

export const processOnboardingCsvErrors = (csvErrors: Array<OnboardingCSVError>): Array<string> => {
  return csvErrors.map((errorItem) => {
    const { error, ...otherFields } = errorItem
    const errorDetails = Object.entries(otherFields)
      .map(([key, value]) => `${key}: ${value}`)
      .join('\n')

    const formattedErrors = Array.isArray(error)
      ? error.map((err) => `- ${err}`).join('\n')
      : `- ${error}`

    return `${errorDetails}\nErrors:\n${formattedErrors}`
  })
}
