import { memo, useMemo } from 'react'
import { Typography } from '@mui/material'
import tw from 'twin.macro'
import { ContractorDetails } from 'src/interfaces/contractor'
import { DateTime } from 'luxon'
import { CollectedDataStatus } from 'src/constants/collectedData'
import { getCollectDataStepTitle } from 'src/utils/collectedData'
import { StepsChip } from '../onboarding/grid/StepsChip'
import { colors } from 'src/theme'

interface Props {
  contractorDetails: ContractorDetails
}

const ContractorFailedStepComponent = ({ contractorDetails }: Props) => {
  const failedAt = useMemo(
    () =>
      contractorDetails?.collectedData.find((item) => item.status === CollectedDataStatus.Failed)
        ?.updatedAt,
    [contractorDetails],
  )

  return (
    <div css={tw`bg-white rounded-2xl mb-4 px-[24px] py-4`}>
      <div css={tw`grid grid-cols-2 gap-4 text-center items-end`}>
        <div>
          <Typography
            fontWeight={600}
            color={colors.GRAY_COOL_GRAY}
            css={tw`mb-1`}
            variant="subtitle1"
          >
            Failed on
          </Typography>
          <Typography color="black" variant="body2">
            {failedAt ? DateTime.fromMillis(failedAt * 1000).toFormat('LLL dd, yyyy') : '-'}
          </Typography>
        </div>
        <div>
          <Typography
            fontWeight={600}
            color={colors.GRAY_COOL_GRAY}
            css={tw`mb-1`}
            variant="subtitle1"
          >
            Failed on stage
          </Typography>
          <StepsChip
            textClassName={tw`text-[14px] font-normal`}
            className={tw`bg-white`}
            titles={contractorDetails.collectedData
              .filter((item) => item.status === CollectedDataStatus.Failed)
              .map((item) => getCollectDataStepTitle(item))}
          />
        </div>
      </div>
    </div>
  )
}

export const ContractorFailedStep = memo(ContractorFailedStepComponent)
