import { Formik } from 'formik'
import { useState } from 'react'
import { EditFieldFormValues } from './EditFieldForm.interface'
import { getEditFieldSchema } from './EditFieldForm.validator'
import { FormikTextField } from 'src/components/shared/form/formik/FormikTextField'
import tw from 'twin.macro'
import { Button } from 'src/components/shared/Button'
import { handleApiError } from 'src/utils/errorHandlers'
import { FormikSelect } from '../form/formik/FormikSelect'
import { BasicSelectOption } from '../form/BasicSelect'
import { FormikDatePicker } from '../form/formik/FormikDatePicker'
import { Dayjs } from 'dayjs'

interface Props {
  label: string
  initialValue?: string | null | number
  placeholder?: string
  options?: Array<BasicSelectOption>
  editFiledType?: 'input' | 'select' | 'date'
  minDateError?: string
  minDate?: Dayjs
  onSubmit(value: string): void | Promise<void>
  onClose(): void
}

export const EditFieldForm = ({
  label,
  placeholder,
  initialValue,
  editFiledType = 'input',
  options = [],
  minDate,
  minDateError,
  onSubmit,
  onClose,
}: Props) => {
  const [isLoading, setIsLoading] = useState<boolean>(false)

  const handleFormSubmit = async (values: EditFieldFormValues) => {
    setIsLoading(true)

    try {
      await onSubmit(values.value)
    } catch (err: any) {
      handleApiError(err)
    } finally {
      setIsLoading(false)
    }
  }

  return (
    <Formik
      initialValues={{ value: initialValue?.toString() || '' }}
      onSubmit={handleFormSubmit}
      validationSchema={getEditFieldSchema(label, editFiledType)}
    >
      {({ handleSubmit }) => (
        <div>
          <div css={tw`px-4 pt-4`}>
            {editFiledType === 'input' && (
              <FormikTextField
                name="value"
                css={tw`w-[304px]`}
                label={label}
                placeholder={placeholder}
                multiline
              />
            )}
            {editFiledType === 'select' && (
              <div css={tw`w-[304px]`}>
                <FormikSelect name="value" label={label} options={options} />
              </div>
            )}
            {editFiledType === 'date' && (
              <div css={tw`w-[304px]`}>
                <FormikDatePicker
                  name="value"
                  label={label}
                  minDate={minDate}
                  minDateError={minDateError}
                />
              </div>
            )}
          </div>
          <div
            css={tw`flex flex-row justify-between items-end  border-0 border-t border-solid border-[#EDEDED] mt-4 p-4 pt-3`}
          >
            <Button
              variant="contained"
              loading={isLoading}
              size="small"
              color="grey"
              onClick={onClose}
            >
              Cancel
            </Button>
            <div css={tw`flex flex-col items-end`}>
              <Button
                variant="contained"
                size="small"
                loading={isLoading}
                onClick={() => handleSubmit()}
              >
                Save
              </Button>
            </div>
          </div>
        </div>
      )}
    </Formik>
  )
}
