import React, { memo, useCallback, useEffect, useMemo, useRef, useState } from 'react'
import tw from 'twin.macro'
import { OnboardingStatus } from 'src/constants/onboarding/enums'
import { Typography } from '@mui/material'
import { OnboardingTabs } from '../tabs/OnboardingTabs'
import { InviteWorkersButton } from '../invite-workers/InviteWorkersButton'
import { ManageTagsButton } from 'src/components/tags/ManageTagsButton'
import { useLocation } from 'react-router-dom'
import { OnboardingsTable } from '../onboardings-table/OnboardingsTable'
import { useDispatch, useSelector } from 'react-redux'
import { getPartnerId } from 'src/selectors/user'
import { getSelectedMarket } from 'src/selectors/market'
import { FetchOnboardingsCounts } from 'src/constants/actionTypes'
import { IOnboardingsContext, OnboardingsContext } from './Onboardings.context'

const OnboardingsComponent = () => {
  const dispatch = useDispatch()
  const { state } = useLocation()

  const partnerId = useSelector(getPartnerId)
  const selectedMarket = useSelector(getSelectedMarket)

  const selectedTabRef = useRef<OnboardingStatus | null>(null)

  const [selectedTab, setSelectedTab] = useState(OnboardingStatus.Created)
  const [isManageTagsOpen, setIsManageTagsOpen] = useState(false)
  const [searchQuery, setSearchQuery] = useState<string>('')
  const [tagsFilter, setTagsFilter] = useState<Array<string>>([])

  const handleSelectTab = useCallback((tab: OnboardingStatus) => {
    selectedTabRef.current = tab
    setSelectedTab(tab)
  }, [])

  useEffect(() => {
    if (!state?.selectedTab || state?.selectedTab === selectedTabRef.current) {
      return
    }

    handleSelectTab(state.selectedTab)
  }, [state?.selectedTab])

  const openManageTags = useCallback(() => {
    setIsManageTagsOpen(true)
  }, [])

  const closeManageTags = useCallback(() => {
    setIsManageTagsOpen(false)
  }, [])

  const fetchOnboardingCounts = useCallback(() => {
    if (!partnerId) {
      return
    }

    const params: any = { partnerId }

    if (selectedMarket?.id) {
      params.partnerMarketId = selectedMarket?.id
    }

    dispatch(FetchOnboardingsCounts.request(params))
  }, [partnerId, selectedMarket?.id])

  useEffect(() => {
    fetchOnboardingCounts()
  }, [fetchOnboardingCounts])

  const onboardingsContextValue = useMemo<IOnboardingsContext>(() => {
    return {
      searchQuery,
      setSearchQuery,

      tagsFilter,
      setTagsFilter,
    }
  }, [searchQuery, setSearchQuery, tagsFilter, setTagsFilter])

  return (
    <OnboardingsContext.Provider value={onboardingsContextValue}>
      <div>
        <div css={tw`flex flex-row items-center justify-between mb-6`}>
          <Typography variant="h2" css={tw`mb-2`}>
            Onboarding
          </Typography>
          <div css={tw`flex`}>
            <ManageTagsButton
              isManageTagsOpen={isManageTagsOpen}
              openManageTags={openManageTags}
              closeManageTags={closeManageTags}
            />
            <InviteWorkersButton />
          </div>
        </div>
        <OnboardingTabs selectedTab={selectedTab} onClick={handleSelectTab} />
        {selectedTab === OnboardingStatus.Created && (
          <OnboardingsTable
            selectedStatus={OnboardingStatus.Created}
            openManageTags={openManageTags}
          />
        )}
        {selectedTab === OnboardingStatus.WaitingForApproval && (
          <OnboardingsTable
            selectedStatus={OnboardingStatus.WaitingForApproval}
            openManageTags={openManageTags}
          />
        )}
        {selectedTab === OnboardingStatus.Failed && (
          <OnboardingsTable
            selectedStatus={OnboardingStatus.Failed}
            openManageTags={openManageTags}
          />
        )}
      </div>
    </OnboardingsContext.Provider>
  )
}

export const Onboardings = memo(OnboardingsComponent)
