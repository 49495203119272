import { Typography, TypographyProps } from '@mui/material'
import tw from 'twin.macro'
import React from 'react'

interface Props extends TypographyProps {
  texts: string[]
}

export const NumericList = ({ texts, ...typographyProps }: Props) => {
  return (
    <>
      <ol css={tw`py-0 my-0`}>
        {texts.map((item, index) => (
          <Typography css={index !== texts.length - 1 ? tw`mb-2` : undefined} {...typographyProps}>
            <li>{item}</li>
          </Typography>
        ))}
      </ol>
    </>
  )
}
