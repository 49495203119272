import { createContext } from 'react'
import { ContractorStatus } from 'src/constants/contractor/enums'

export interface IContractorsTableContext {
  statusFilter: Array<ContractorStatus>
  setStatusFilter(statusFilter: Array<ContractorStatus>): void

  tagsFilter: Array<string>
  setTagsFilter(statusFilter: Array<string>): void

  refreshContractors(): Promise<void>
}

export const ContractorsTableContext = createContext<IContractorsTableContext>({
  statusFilter: [],
  setStatusFilter: () => {},

  tagsFilter: [],
  setTagsFilter: () => {},

  refreshContractors: async () => {},
})
