import React, { memo, useCallback } from 'react'
import tw from 'twin.macro'
import { ProductsMinor, CancelSmallMinor } from '@shopify/polaris-icons'
import { Tag } from 'src/interfaces/tag'
import { Typography, Tooltip } from '@mui/material'
import { cutLongWord } from 'src/utils/baseUtils'

interface Props {
  tag: Tag
  onRemove(tag: Tag): void
}

const RemovableTagComponent = ({ tag, onRemove }: Props) => {
  const handleRemove = useCallback(() => {
    onRemove(tag)
  }, [tag, onRemove])

  const shouldShowTooltip = tag.name.length > 20

  return (
    <div css={tw`bg-[#EDEDED] rounded flex py-1 px-2`}>
      <ProductsMinor css={tw`self-center`} width={20} height={20} />
      {shouldShowTooltip ? (
        <Tooltip title={tag.name} arrow>
          <Typography variant="subtitle1" css={tw`mx-1 my-0`}>
            {cutLongWord(tag.name)}
          </Typography>
        </Tooltip>
      ) : (
        <Typography variant="subtitle1" css={tw`mx-1 my-0`}>
          {cutLongWord(tag.name)}
        </Typography>
      )}
      <div css={tw`h-5 cursor-pointer hover:opacity-50`} onClick={handleRemove}>
        <CancelSmallMinor width={20} />
      </div>
    </div>
  )
}

export const RemovableTag = memo(RemovableTagComponent)
