import {
  BlogMajor,
  ChecklistAlternateMajor,
  CustomersMajor,
  FinancesMajor,
  NoteMajor,
  PageDownMajor,
  QuestionMarkMajor,
  SecureMajor,
  ShipmentMajor,
  SmileyHappyMajor,
  TextBlockMajor,
} from '@shopify/polaris-icons'
import DriverIcon from 'src/assets/icons/driver-front.svg'
import BGCIcon from 'src/assets/icons/bgc.svg'
import BookmarkIcon from 'src/assets/icons/bookmark.svg'
import { StitchVendorStates } from './payment/enums'

export enum CollectedDataStatus {
  Created = 'created',
  InProgress = 'in_progress',
  WaitingForInternalApproval = 'waiting_for_internal_approval',
  WaitingForApproval = 'waiting_for_approval',
  Completed = 'completed',
  Failed = 'failed', // Applies only for BG check
}

export enum CollectedDataStepType {
  DriverLicense = 'driver_license',
  VehicleAndInsurance = 'vehicle_and_insurance',
  InsuranceAmountDeclaration = 'insurance_amount_declaration',
  VehicleRegistration = 'vehicle_registration',
  VaccinationInformation = 'vaccination_information',
  BackgroundCheck = 'background_check',
  PaymentMethod = 'payment_method',
  InsuranceBeneficiary = 'insurance_beneficiary',
  SignContract = 'sign_contract',
  Training = 'training',
  StitchPayment = 'stitch_payment',
  Questionnaire = 'questionnaire',
  MvrUpload = 'mvr_upload',
  InPersonMeeting = 'in_person_meeting',
  Form = 'form',
  BusinessEntity = 'business_entity',
}

export const collectedDataStepsTitle = {
  [CollectedDataStepType.Questionnaire]: 'Questionnaire',
  [CollectedDataStepType.Form]: '',
  [CollectedDataStepType.DriverLicense]: "Driver's license",
  [CollectedDataStepType.VehicleAndInsurance]: 'Vehicle and insurance',
  [CollectedDataStepType.InsuranceAmountDeclaration]: 'Insurance amount declaration',
  [CollectedDataStepType.VehicleRegistration]: 'Vehicle registration',
  [CollectedDataStepType.VaccinationInformation]: 'Vaccination information',
  [CollectedDataStepType.BackgroundCheck]: 'Background check',
  [CollectedDataStepType.PaymentMethod]: 'Payment method',
  [CollectedDataStepType.StitchPayment]: 'Payment method',
  [CollectedDataStepType.InsuranceBeneficiary]: 'Insurance beneficiary',
  [CollectedDataStepType.SignContract]: 'Sign the contract',
  [CollectedDataStepType.Training]: 'Training',
  [CollectedDataStepType.MvrUpload]: 'MVR Upload',
  [CollectedDataStepType.InPersonMeeting]: 'In-person meeting',
  [CollectedDataStepType.BusinessEntity]: 'Business Entity',
}

export const collectedDataStepIconsMap = {
  [CollectedDataStepType.Questionnaire]: <QuestionMarkMajor width={20} />,
  [CollectedDataStepType.Form]: <ChecklistAlternateMajor width={20} />,
  [CollectedDataStepType.DriverLicense]: <img src={DriverIcon} />,
  [CollectedDataStepType.VehicleAndInsurance]: <ShipmentMajor width={20} />,
  [CollectedDataStepType.InsuranceAmountDeclaration]: <NoteMajor height={20} />,
  [CollectedDataStepType.VehicleRegistration]: <TextBlockMajor height={20} width={20} />,
  [CollectedDataStepType.VaccinationInformation]: <SecureMajor height={20} width={20} />,
  [CollectedDataStepType.BackgroundCheck]: <img src={BGCIcon} />,
  [CollectedDataStepType.PaymentMethod]: <FinancesMajor height={20} width={20} />,
  [CollectedDataStepType.StitchPayment]: <FinancesMajor height={20} width={20} />,
  [CollectedDataStepType.InsuranceBeneficiary]: <CustomersMajor height={18} width={16} />,
  [CollectedDataStepType.SignContract]: <BlogMajor height={20} width={19} />,
  [CollectedDataStepType.Training]: <img src={BookmarkIcon} />,
  [CollectedDataStepType.MvrUpload]: <PageDownMajor width={20} />,
  [CollectedDataStepType.InPersonMeeting]: <SmileyHappyMajor height={20} width={20} />,
  [CollectedDataStepType.BusinessEntity]: <NoteMajor height={20} width={20} />,
}

export enum CollectDataFormItemType {
  TextBlock = 'textBlock',
  TextInput = 'textInput',
  Date = 'date',
  Number = 'number',
  Select = 'select',
  Radio = 'radio',
  Checkbox = 'checkbox',
  Video = 'video',
  File = 'file',
}

export enum CollectDataStepDeclineReason {
  NotOwnerOfInsurance = 'not_owner_of_insurance',
  DeclinedByCustomer = 'declined_by_customer',
}

export enum BackgroundCheckDataStatus {
  Created = 'created',
  Pending = 'pending',
  Dispute = 'dispute',
  InfoRequested = 'info_requested',
  Consider = 'consider',
  PreAdverse = 'pre_adverse',
  FinalAdverse = 'final_adverse',
}

export const backgroundCheckDataStatusTitles = {
  [BackgroundCheckDataStatus.Created]: 'Created',
  [BackgroundCheckDataStatus.Pending]: 'Pending',
  [BackgroundCheckDataStatus.Dispute]: 'Dispute',
  [BackgroundCheckDataStatus.InfoRequested]: 'Info requested',
  [BackgroundCheckDataStatus.Consider]: 'Consider',
  [BackgroundCheckDataStatus.PreAdverse]: 'Pre adverse',
  [BackgroundCheckDataStatus.FinalAdverse]: 'Final adverse',
}

export const getBackgroundCheckReminderText = (
  dataStatus: BackgroundCheckDataStatus,
  stepStatus: CollectedDataStatus,
): string => {
  if (
    dataStatus === BackgroundCheckDataStatus.Created &&
    stepStatus === CollectedDataStatus.Created
  ) {
    return "Contractor has started this step but has not finished. You can remind them to complete it by clicking 'Resend an invite' at the bottom left."
  }

  if (dataStatus === BackgroundCheckDataStatus.Dispute) {
    return 'Most disputes are resolved within 30 days but some may take up to 45 days depending on the information provided.'
  }

  if (
    dataStatus === BackgroundCheckDataStatus.Created ||
    dataStatus === BackgroundCheckDataStatus.InfoRequested
  ) {
    return "You can remind the contractor to complete this step by clicking the 'Resend an invite' button at the bottom left."
  }

  if (dataStatus === BackgroundCheckDataStatus.PreAdverse) {
    return 'The contractor received a "pre-adverse" email from Yardstik. They should follow the instructions in that email or contact Yardstik at support@yardstik.com for assistance if they want to dispute results.'
  }

  return ''
}

export const stitchVendorStatusTitles = {
  [StitchVendorStates.Approved]: 'Approved',
  [StitchVendorStates.Pending]: 'Pending',
  [StitchVendorStates.Document]: 'Document',
  [StitchVendorStates.Suspended]: 'Suspended',
  [StitchVendorStates.Rejected]: 'Rejected',
  [StitchVendorStates.ManualReview]: 'Manual review',
}

export const getStitchVendorReminderText = (
  stepStatus: CollectedDataStatus,
  vendorStatus?: StitchVendorStates | null,
): string => {
  if (!vendorStatus && stepStatus === CollectedDataStatus.Created) {
    return 'The contractor has not begun this step. You can remind them by clicking the “Resend an Invite” button on the bottom left.'
  }

  if (vendorStatus === StitchVendorStates.Pending) {
    return 'The contractor is awaiting approval by our payment processor. This typically takes less than 1 business day.'
  }

  if (vendorStatus === StitchVendorStates.Approved) {
    return 'The contractor has been verified and is approved to receive payments.'
  }

  if (vendorStatus === StitchVendorStates.ManualReview) {
    return 'The verification process requires human review by our payment processor. We will notify the contractor if additional information is needed from them. This process typically takes 1-3 business days. If it exceeds this time, please contact support@gigsafe.com or start a new chat on the purple support bubble on the bottom left of your screen.'
  }

  if (vendorStatus === StitchVendorStates.Suspended) {
    return 'The contractor is no longer approved for payments due to suspected fraud or manual decision by our payment processor.'
  }

  if (vendorStatus === StitchVendorStates.Rejected) {
    return 'The contractor did not pass verification due to prior history with banks or suspected fraudulent activity.'
  }

  return ''
}

export enum CollectedDataApprovalType {
  AutoApproval = 'auto_approval',
  InternalApproval = 'internal_approval',
  CustomerApproval = 'customer_approval',
}

export const collectedDataStatusTitle = {
  [CollectedDataStatus.Created]: 'Not Started',
  [CollectedDataStatus.InProgress]: 'In progress',
  [CollectedDataStatus.WaitingForInternalApproval]: 'Pending',
  [CollectedDataStatus.WaitingForApproval]: 'Waiting for approval',
  [CollectedDataStatus.Completed]: 'Approved',
  [CollectedDataStatus.Failed]: 'Failed',
}
