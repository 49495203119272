import { CircleCancelMinor } from '@shopify/polaris-icons'
import React, { memo, useCallback, useMemo } from 'react'
import { createPortal } from 'react-dom'
import { CollectedData } from 'src/interfaces/collectedData'
import { ContractorDetails } from 'src/interfaces/contractor'
import tw from 'twin.macro'
import { Button } from '../shared/Button'
import { ContractorStepActionsButton } from './ContractorStepActionsButton'
import { CollectedDataStatus, CollectedDataApprovalType } from 'src/constants/collectedData'
import { ContractorStepDocuments } from './ContractorStepDocuments'
import { getContractorStepData } from './ContractorStepData'
import { ContractorStepHeader } from './ContractorStepHeader'
import ApproveButton from './step-actions/ApproveButton'
import DeclineButton from './step-actions/DeclineButton'

interface Props {
  collectedData: CollectedData
  contractorDetails: ContractorDetails
  onRefreshContractors(): void | Promise<void>
  delayed?: boolean
  modalId: string
  openModalId: string | null
  setOpenModalId: React.Dispatch<React.SetStateAction<string | null>>
}

const ContractorStepItemComponent = ({
  collectedData,
  contractorDetails,
  onRefreshContractors,
  delayed,
  modalId,
  openModalId,
  setOpenModalId,
}: Props) => {
  const isStepDetailsModalOpen = openModalId === modalId

  const toggleStepDetailsModal = useCallback(() => {
    setOpenModalId((prev: string | null) => (prev === modalId ? null : modalId))
  }, [modalId, setOpenModalId])

  const stepCanBeRecollected = useMemo(
    () =>
      contractorDetails.collectDataTemplate?.collectDataTemplateSteps?.some((template) => {
        const hasExactMatchInTemplate =
          template.label === collectedData.collectDataStep.label &&
          template.type === collectedData.collectDataStep.type

        const hasRecollectOptionForSameStepType =
          template.type === collectedData.collectDataStep.type &&
          collectedData.collectDataStep.recollectSameTypeSteps

        return hasExactMatchInTemplate || hasRecollectOptionForSameStepType
      }),
    [collectedData, contractorDetails],
  )

  const stepData = useMemo(
    () =>
      getContractorStepData({
        collectedData,
        contractorDetails,
      }),
    [collectedData, contractorDetails],
  )

  const stepStatus = useMemo(
    () =>
      !!collectedData?.dataStatus && collectedData.status === CollectedDataStatus.Created
        ? CollectedDataStatus.InProgress
        : collectedData.status,
    [collectedData.status, collectedData.dataStatus],
  )

  const portalRoot = document.getElementById('step-details')

  const showDetails = !!stepData || !!collectedData.documents.length

  return (
    <div css={tw`flex flex-col gap-[16px] bg-white rounded-2xl overflow-hidden p-4`}>
      <ContractorStepHeader
        collectedData={collectedData}
        delayed={delayed}
        stepStatus={stepStatus}
        onRefreshContractors={onRefreshContractors}
      />

      {showDetails ||
      collectedData.status === CollectedDataStatus.WaitingForApproval ||
      stepCanBeRecollected ||
      (collectedData.status === CollectedDataStatus.Completed &&
        collectedData.collectDataStep.approvalType ===
          CollectedDataApprovalType.CustomerApproval) ? (
        <ContractorStepActionsButton
          contractorDetails={contractorDetails}
          collectedData={collectedData}
          recollecting={collectedData.isRecollecting}
          stepCanBeRecollected={stepCanBeRecollected}
          showDetails={showDetails}
          onAction={onRefreshContractors}
          toggleStepDetailsModal={toggleStepDetailsModal}
        />
      ) : null}

      {portalRoot &&
        isStepDetailsModalOpen &&
        createPortal(
          <div
            css={tw`rounded-2xl shadow-lg bg-white py-4 min-w-[380px] flex flex-col pointer-events-auto overflow-y-auto h-full w-full`}
          >
            <div css={tw`px-4`}>
              <ContractorStepHeader
                collectedData={collectedData}
                delayed={delayed}
                stepStatus={stepStatus}
                onRefreshContractors={onRefreshContractors}
              />
              <div css={tw`w-full mt-4 h-[1px] bg-[#EDEDED]`} />
            </div>
            <div css={tw`flex-1 overflow-y-auto px-4`}>
              {stepData}
              {!!collectedData.documents?.length && (
                <ContractorStepDocuments
                  collectedData={collectedData}
                  header={
                    <ContractorStepHeader
                      collectedData={collectedData}
                      delayed={delayed}
                      stepStatus={stepStatus}
                      onRefreshContractors={onRefreshContractors}
                    />
                  }
                />
              )}
            </div>

            <div css={tw`flex justify-end mt-4 flex-col gap-4 px-4`}>
              {showDetails && collectedData.status === CollectedDataStatus.WaitingForApproval && (
                <div css={tw`grid grid-cols-2 gap-2 w-full`}>
                  <ApproveButton
                    contractorDetails={contractorDetails}
                    collectedData={collectedData}
                    onAction={onRefreshContractors}
                  />
                  <DeclineButton
                    contractorDetails={contractorDetails}
                    collectedData={collectedData}
                    onAction={onRefreshContractors}
                  />
                </div>
              )}
              <Button
                fullWidth
                color="white"
                size="small"
                onClick={toggleStepDetailsModal}
                endIcon={<CircleCancelMinor width={20} />}
              >
                Close
              </Button>
            </div>
          </div>,
          portalRoot,
        )}
    </div>
  )
}

export const ContractorStepItem = memo(ContractorStepItemComponent)
