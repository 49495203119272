import { GridColDef, GridRenderCellParams, GridValueGetterParams } from '@mui/x-data-grid-pro'
import { gridFormatters, gridValueGetters } from 'src/utils/grid'
import { Contractor } from 'src/interfaces/contractor'
import { ContractorStatusChip } from 'src/components/contractor/grid/ContractorStatusChip'
import { TableTags } from 'src/components/tags/TableTags'
import { ContractorState } from './enums'
import { Tag } from 'src/interfaces/tag'
import { contractorStatusTitles } from './constants'

const sharedContractorColumns: Array<GridColDef> = [
  {
    field: 'externalId',
    headerName: 'Contractors ID',
    flex: 2,
    sortable: true,
  },
  {
    field: 'name',
    headerName: 'Name',
    flex: 2,
    sortable: true,
  },
]

const statusCol = {
  field: 'status',
  headerName: 'Status',
  flex: 2,
  sortable: true,
  valueGetter: (params: GridValueGetterParams<Contractor>) =>
    contractorStatusTitles[params.row.status],
  renderCell: (params: GridRenderCellParams<Contractor>) => {
    return <ContractorStatusChip status={params.row.status} />
  },
}

const lastColumns = [
  {
    field: 'phone',
    headerName: 'Phone number',
    flex: 2,
    sortable: true,
  },
  {
    field: 'email',
    headerName: 'Email',
    flex: 3,
    sortable: true,
  },
  {
    field: 'tags',
    headerName: 'Tag(s)',
    flex: 3,
    sortable: false,
    sortComparator: (v1: Tag[], v2: Tag[]) => {
      return v1.length - v2.length
    },
    renderCell: (params: GridRenderCellParams<Contractor>) =>
      params.row?.tags.length ? <TableTags tags={params.row?.tags || []} /> : <span>-</span>,
  },
  {
    field: 'referral',
    headerName: 'Referral',
    flex: 2,
    sortable: true,
    valueGetter: (params: GridValueGetterParams<Contractor>) => params.value || '-',
  },
]

export const contractorColumns = {
  [ContractorState.Active]: [
    ...sharedContractorColumns,
    statusCol,
    ...lastColumns,
    {
      field: 'dateOnboarded',
      headerName: 'Date onboarded',
      flex: 2,
      sortable: true,
      valueGetter: gridValueGetters.parseDate,
      valueFormatter: gridFormatters.formatShortDate,
    },
  ],
  [ContractorState.Deactivated]: [
    ...sharedContractorColumns,
    ...lastColumns,
    {
      field: 'dateDeactivated',
      headerName: 'Date deactivated',
      flex: 2,
      sortable: true,
      valueGetter: gridValueGetters.parseDate,
      valueFormatter: gridFormatters.formatShortDate,
    },
  ],
  [ContractorState.Recollection]: [...sharedContractorColumns, statusCol, ...lastColumns],
}

export const contractorInitialSortFields = {
  [ContractorState.Active]: 'dateOnboarded',
  [ContractorState.Deactivated]: 'dateDeactivated',
  [ContractorState.Recollection]: undefined,
}

export const contractorPageSizeOptions = [100, 250, 500, 1000]

export const ONBOARDINGS_COLUMNS_KEY = 'contractorsColumns'
