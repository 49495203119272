import { EarningPaymentMethod, PaymentStatus, SingleFailureReasonType } from './enums'

export const paymentStatusTitle = {
  [PaymentStatus.Paid]: 'Paid',
  [PaymentStatus.Pending]: 'Pending',
}

export const PayoutMethodTitles = {
  [EarningPaymentMethod.Ach]: 'ACH',
  [EarningPaymentMethod.Instant]: 'Debit',
}

export const failureReasonTitles = {
  [SingleFailureReasonType.BadPayment]: 'Bad payment',
  [SingleFailureReasonType.ContractorNotOnboarded]: 'Contractor is not onboarded',
  [SingleFailureReasonType.ContractorDeactivated]: 'Contractor is deactivated',
  [SingleFailureReasonType.NoValidPaymentMethod]: 'No valid payment method on file',
  [SingleFailureReasonType.UnsuccessfulPaymentAttempt]: 'Payment attempt was unsuccessful',
  [SingleFailureReasonType.Unknown]: 'Reason unknown',
}
