import { memo, useCallback, useState } from 'react'
import tw from 'twin.macro'
import { Button } from 'src/components/shared/Button'
import { SubmitConfirmModal } from 'src/components/shared/SubmitConfirmModal'
import { CircleTickMajor } from '@shopify/polaris-icons'

interface Props {
  onActivate(): void
}

const ActivateContractorButtonComponent = ({ onActivate }: Props) => {
  const [isConfirmOpen, setIsConfirmOpen] = useState(false)

  const handleOpenConfirm = useCallback(() => {
    setIsConfirmOpen(true)
  }, [])

  const handleCloseConfirm = useCallback(() => {
    setIsConfirmOpen(false)
  }, [])

  return (
    <div css={tw`w-full bg-white`}>
      <Button
        className={tw`px-8`}
        size="small"
        color="grey"
        onClick={handleOpenConfirm}
        fullWidth
        endIcon={<CircleTickMajor width={20} />}
      >
        Activate contractor
      </Button>
      <SubmitConfirmModal
        isOpen={isConfirmOpen}
        title="Activate contractor?"
        text="Are you sure you want to activate contractor?"
        submitLabel="Activate"
        onClose={handleCloseConfirm}
        onSubmit={onActivate}
      />
    </div>
  )
}

export const ActivateContractor = memo(ActivateContractorButtonComponent)
