import {
  Logout,
  FetchContractorsCounts,
  FetchContractorActivitiesLogs,
} from 'src/constants/actionTypes'
import { Action } from 'src/utils/reduxUtils'
import { ContractorState } from './types'

const getInitialState = (): ContractorState => ({
  contractorsCounts: null,
  contractorActivitiesLogs: null,
})

export const contractorReducer = (
  state: ContractorState = getInitialState(),
  action: Action<any>,
): ContractorState => {
  switch (action.type) {
    case FetchContractorsCounts.type.SUCCESS:
      return {
        ...state,
        contractorsCounts: action.payload,
      }
    case FetchContractorActivitiesLogs.type.SUCCESS:
      return {
        ...state,
        contractorActivitiesLogs: action.payload,
      }
    case Logout.type.SUCCESS:
      return getInitialState()
    default:
      return state
  }
}
