import { memo } from 'react'
import tw from 'twin.macro'
import { CustomersMajor, PauseCircleMajor } from '@shopify/polaris-icons'
import { ContractorTab } from './ContractorTab'
import { useSelector } from 'react-redux'
import { ContractorState } from 'src/constants/contractor/enums'
import RecollectIcon from 'src/assets/icons/recollect.svg'
import RecollectPurpleIcon from 'src/assets/icons/recollect-purple.svg'
import { getContractorsCounts } from 'src/selectors/contractor'
import { addS } from 'src/utils/baseUtils'
import { colors } from 'src/theme'

const tabs = [
  {
    state: ContractorState.Active,
    title: 'Active',
    renderIcon: (selected: boolean) => (
      <CustomersMajor width={20} fill={selected ? colors.PRIMARY_PURPLE_600 : colors.GRAY_BLACK} />
    ),
  },
  {
    state: ContractorState.Deactivated,
    title: 'Deactivated',
    renderIcon: (selected: boolean) => (
      <PauseCircleMajor
        width={20}
        fill={selected ? colors.PRIMARY_PURPLE_600 : colors.GRAY_BLACK}
      />
    ),
  },
  {
    state: ContractorState.Recollection,
    title: 'Needs Review',
    renderIcon: (selected: boolean) => (
      <img
        src={selected ? RecollectPurpleIcon : RecollectIcon}
        css={tw`self-center`}
        width={20}
        height={20}
      />
    ),
  },
]

interface Props {
  selectedTab: ContractorState
  onClick(tab: ContractorState): void
}

const ContractorTabsComponent = ({ selectedTab, onClick }: Props) => {
  const contractorCounts = useSelector(getContractorsCounts)

  return (
    <div css={tw`bg-white rounded-t-lg flex w-full overflow-hidden`}>
      {tabs.map((item) => (
        <ContractorTab
          title={item.title}
          key={item.title}
          state={item.state}
          icon={item.renderIcon(item.state === selectedTab)}
          subtitle={
            contractorCounts
              ? `${contractorCounts[item.state] || 0} ${addS(
                  contractorCounts[item.state],
                  'contractor',
                )}`
              : ''
          }
          selected={item.state === selectedTab}
          onClick={onClick}
        />
      ))}
    </div>
  )
}

export const ContractorTabs = memo(ContractorTabsComponent)
