import CssBaseline from '@mui/material/CssBaseline'
import { ThemeProvider } from '@mui/material/styles'
import { theme } from './theme'
import { store } from './reduxStore'
import { Provider } from 'react-redux'
import { memo, useEffect } from 'react'
import { SnackbarProvider } from 'notistack'
import { LocalizationProvider } from '@mui/x-date-pickers'
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs'
import { AppRoutes } from './AppRoutes'
import { SnackbarErrorWithDetails } from './components/shared/SnackbarErrorWithDetails'
import Intercom from '@intercom/messenger-js-sdk'

export const App = memo(() => {
  useEffect(() => {
    if (process.env.REACT_APP_INTERCOM_APP_ID) {
      Intercom({
        region: 'us',
        app_id: process.env.REACT_APP_INTERCOM_APP_ID,
        alignment: 'left',
        z_index: 1,
      })
    }
  }, [])

  return (
    <Provider store={store}>
      <CssBaseline />
      <ThemeProvider theme={theme}>
        <LocalizationProvider dateAdapter={AdapterDayjs}>
          <SnackbarProvider
            maxSnack={6}
            anchorOrigin={{
              vertical: 'bottom',
              horizontal: 'center',
            }}
            Components={{
              errorWithDetails: SnackbarErrorWithDetails,
            }}
          >
            <AppRoutes />
          </SnackbarProvider>
        </LocalizationProvider>
      </ThemeProvider>
    </Provider>
  )
})
