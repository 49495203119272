import { Typography } from '@mui/material'
import { memo, useContext } from 'react'
import tw from 'twin.macro'
import EmptyImage from 'src/assets/images/empty-clipboard.svg'
import EmptySearchImage from 'src/assets/images/empty-search.svg'
import { colors } from 'src/theme'
import { ContractorsContext } from '../contractors/Contractors.context'
import { ContractorsTableContext } from '../contractors-table/ContractorsTable.context'

const EmptyContractorsComponent = () => {
  const { searchQuery } = useContext(ContractorsContext)
  const { statusFilter } = useContext(ContractorsTableContext)

  const isSearchUsed = Boolean(searchQuery || statusFilter.length)

  return (
    <div css={tw`flex flex-1 h-full justify-center items-center flex-col`}>
      <img src={isSearchUsed ? EmptySearchImage : EmptyImage} />
      <Typography css={tw`mb-1 mt-2`} variant="h4" fontWeight={600}>
        {isSearchUsed ? 'No contractors found' : 'This board is empty'}
      </Typography>
      <Typography color={colors.SHADES_GREY_400} variant="body2">
        {isSearchUsed
          ? 'Please try a different search query or filters.'
          : 'No contractor records found. Please add new contractors to see them listed here.'}
      </Typography>
    </div>
  )
}

export const EmptyContractors = memo(EmptyContractorsComponent)
