import React, { memo } from 'react'
import tw from 'twin.macro'
import { Tooltip, Typography } from '@mui/material'
import { QuestionMarkMinor } from '@shopify/polaris-icons'
import { colors } from 'src/theme'
import { ContractorStepStatus } from './ContractorStepStatus'
import {
  collectedDataStepIconsMap,
  CollectedDataStatus,
  CollectedDataApprovalType,
} from 'src/constants/collectedData'
import { getCollectDataStepTitle } from 'src/utils/collectedData'
import { CollectedData } from 'src/interfaces/collectedData'
import dayjs from 'dayjs'

interface ContractorStepHeaderProps {
  collectedData: CollectedData
  delayed?: boolean
  stepStatus: CollectedDataStatus
  onRefreshContractors(): void | Promise<void>
}

const ContractorStepHeaderComponent = ({
  collectedData,
  delayed,
  stepStatus,
}: ContractorStepHeaderProps) => {
  return (
    <div>
      {delayed && (
        <>
          <div css={tw`pb-4 flex flex-row items-center`}>
            <Typography css={tw`mr-1`} variant="overline">
              DELAYED STEP
            </Typography>
            <Tooltip
              color="black"
              title={
                <>
                  <Typography css={tw`mb-2`} variant="h6" color={colors.SHADES_GREY_200}>
                    Delayed step
                  </Typography>
                  <Typography variant="body2" color={colors.SHADES_GREY_300}>
                    A step the contractor cannot complete without a prior step being approved first.
                    To initiate this step for a contractor, please approve all steps in 'Waiting for
                    Approval' tab. They will then be sent a notification with a link to continue
                    onboarding.
                  </Typography>
                </>
              }
              placement="right-end"
            >
              <span css={tw`h-5 cursor-pointer`}>
                <QuestionMarkMinor width={20} />
              </span>
            </Tooltip>
          </div>
          <div css={tw`mb-4 border-0 border-b border-solid border-[#EDEDED]`} />
        </>
      )}

      <div css={tw`flex flex-row items-center justify-between`}>
        <div css={tw`flex items-center`}>
          <div>{collectedDataStepIconsMap[collectedData.collectDataStep.type]}</div>
          <div css={tw`ml-3`}>
            <Typography css={tw`font-semibold`} variant="h6">
              {getCollectDataStepTitle(collectedData)}
            </Typography>
            <ContractorStepStatus
              recollecting={
                collectedData.isRecollecting ||
                (collectedData.status === CollectedDataStatus.Created &&
                  !!collectedData.reviewMetadata?.declineReason)
              }
              status={stepStatus}
            />
          </div>
        </div>
        {collectedData.status === CollectedDataStatus.Completed &&
          collectedData.collectDataStep.approvalType ===
            CollectedDataApprovalType.CustomerApproval && (
            <div css={tw`flex-col flex items-end`}>
              <Typography variant="h6" color={colors.SHADES_GREY_400}>
                Expiration date
              </Typography>
              <Typography variant="body2">
                {collectedData.expiresAt
                  ? dayjs(collectedData.expiresAt).format('MM/DD/YYYY')
                  : 'Not set'}
              </Typography>
            </div>
          )}
      </div>
    </div>
  )
}

export const ContractorStepHeader = memo(ContractorStepHeaderComponent)
