import React, { memo, ReactNode, useMemo, useState } from 'react'
import Modal from '@mui/material/Modal'
import tw from 'twin.macro'
import { ModalHeader } from 'src/components/shared/ModalHeader'
import { ContractorDetails } from 'src/interfaces/contractor'
import { clientSortCollectedData, getCollectDataStepTitle } from 'src/utils/collectedData'
import { CollectedDataStatus } from 'src/constants/collectedData'
import { ContractorPartnerDriverInfo } from './info/ContractorPartnerDriverInfo'
import { ContractorDetailsTabs } from './tabs/ContractorDetailsTabs'
import { OnboardingStepsTab } from './tabs/OnboardingStepsTab'
import { AddOnsTab } from './tabs/AddOnsTab'
import { ActivitiesTab } from './tabs/ActivitiesTab'
import { ContractorTab } from './tabs/enums'
import { ContractorActions } from './contractor-actions/ContractorActions'

interface Props {
  children?: ReactNode
  contractorDetails: ContractorDetails
  showWaitingForApprovalStepsFirst?: boolean
  flow: 'onboardings' | 'contractors'
  onClose(): void
  onRefresh(): Promise<void>
}

const ContractorDetailsModalComponent = ({
  contractorDetails,
  flow,
  showWaitingForApprovalStepsFirst,
  onClose,
  onRefresh,
}: Props) => {
  const container = useMemo(() => document.getElementById('root'), [])

  const [activeTab, setActiveTab] = useState<ContractorTab>(ContractorTab.Onboarding)

  const processedContractorDetails = useMemo(
    () => ({
      ...contractorDetails,
      collectedData: clientSortCollectedData(
        contractorDetails.collectedData,
        showWaitingForApprovalStepsFirst,
      ),
    }),
    [contractorDetails, showWaitingForApprovalStepsFirst],
  )

  return (
    <Modal
      container={container}
      open
      onClose={onClose}
      aria-labelledby="parent-modal-title"
      aria-describedby="parent-modal-description"
      css={tw`flex flex-row w-full h-full`}
    >
      <>
        <div css={tw`w-[858px] h-full bg-white flex flex-col`}>
          <ModalHeader
            title="Contractor details"
            onClose={onClose}
            className={tw`text-black h-[48px]`}
          />
          <div css={tw`flex h-[calc(100vh-48px)]`}>
            <div
              css={tw`max-w-[400px] flex-1 flex flex-col justify-between  border-0 border-r border-solid border-r-[#EDEDED]`}
            >
              <ContractorPartnerDriverInfo
                flow={flow}
                contractorDetails={processedContractorDetails}
                onboardingStatus={processedContractorDetails.onboarding.status}
                onboardingFailedAt={processedContractorDetails.onboarding.failedAt}
                onboardingFailedSteps={processedContractorDetails.collectedData
                  .filter((item) => item.status === CollectedDataStatus.Failed)
                  .map(getCollectDataStepTitle)}
                onboardingExpiredSteps={
                  processedContractorDetails.deactivationStepType
                    ? [processedContractorDetails.deactivationStepType]
                    : undefined
                }
                onUpdate={onRefresh}
              />
              <ContractorActions
                contractorDetails={processedContractorDetails}
                flow={flow}
                onRefresh={onRefresh}
                onClose={onClose}
              />
            </div>
            <div css={tw`w-[458px] flex-1 bg-[#EDEDED]`}>
              <ContractorDetailsTabs selectedTab={activeTab} onClick={setActiveTab} />
              {activeTab === ContractorTab.Onboarding && (
                <OnboardingStepsTab
                  contractorDetails={processedContractorDetails}
                  onRefresh={onRefresh}
                  flow={flow}
                />
              )}
              {activeTab === ContractorTab.Addons && (
                <AddOnsTab contractorDetails={processedContractorDetails} onUpdate={onRefresh} />
              )}
              {activeTab === ContractorTab.Activities && (
                <ActivitiesTab contractorId={contractorDetails.id} />
              )}
            </div>
          </div>
        </div>
        <div id="step-details" css={tw`flex flex-1 p-6 max-w-[560px] w-full pointer-events-none`} />
      </>
    </Modal>
  )
}

export const ContractorDetailsModal = memo(ContractorDetailsModalComponent)
