import React, { useState, useCallback, useMemo } from 'react'
import tw from 'twin.macro'
import { CalendarMinor } from '@shopify/polaris-icons'
import { Button } from '../../shared/Button'
import { Modal } from '@mui/material'
import { ModalHeader } from '../../shared/ModalHeader'
import { EditFieldForm } from '../../shared/edit-field-form/EditFieldForm'
import dayjs from 'dayjs'
import { useDispatch } from 'react-redux'
import { UpdateCollectData } from 'src/constants/actionTypes'
import { createAsyncAction } from 'src/utils/reduxUtils'
import { showToast } from 'src/utils/toast'
import { handleApiError } from 'src/utils/errorHandlers'
import { CollectedData } from 'src/interfaces/collectedData'
import { ContractorDetails } from 'src/interfaces/contractor'

interface Props {
  collectedData: CollectedData
  contractorDetails: ContractorDetails
  onAction(): void | Promise<void>
}

const SetExpirationButton: React.FC<Props> = ({ collectedData, contractorDetails, onAction }) => {
  const dispatch = useDispatch()
  const [isModalOpen, setIsModalOpen] = useState(false)
  const container = useMemo(() => document.getElementById('root'), [])

  const openModal = useCallback(() => setIsModalOpen(true), [])
  const closeModal = useCallback(() => setIsModalOpen(false), [])

  const handleSetExpirationDate = useCallback(
    async (expiresAt: string) => {
      try {
        await createAsyncAction(
          dispatch,
          UpdateCollectData.request({
            stepId: collectedData.collectDataStep.id,
            partnerDriverId: contractorDetails.id,
            expiresAt,
          }),
        )

        await onAction()
        closeModal()
        showToast('Expiration date has been saved.')
      } catch (err) {
        handleApiError(err)
      }
    },
    [collectedData, contractorDetails, onAction, dispatch, closeModal],
  )

  return (
    <>
      <Button
        onClick={openModal}
        fullWidth
        size="small"
        color="grey"
        css={[tw`flex items-center justify-center rounded flex-1`]}
        endIcon={<CalendarMinor width={20} />}
      >
        Set expiration date
      </Button>

      <Modal
        container={container}
        open={isModalOpen}
        onClose={closeModal}
        aria-labelledby="set-expiration-title"
        css={tw`flex justify-center items-center backdrop-blur`}
      >
        <div css={tw`bg-white rounded-lg`}>
          <ModalHeader title="Set expiration date" onClose={closeModal} />
          <EditFieldForm
            label="Expiration date"
            editFiledType="date"
            minDate={dayjs().add(1, 'day')}
            minDateError="Expiration date should be at least 1 day in the future"
            initialValue={collectedData.expiresAt}
            onSubmit={handleSetExpirationDate}
            onClose={closeModal}
          />
        </div>
      </Modal>
    </>
  )
}

export default SetExpirationButton
