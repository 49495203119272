import { Typography } from '@mui/material'
import dayjs from 'dayjs'
import { ReactNode, useMemo } from 'react'
import { colors } from 'src/theme'
import { TitledTooltip } from '../shared/TitledTooltip'
import { BulletList } from '../shared/BulletList'
import React from 'react'
import tw from 'twin.macro'
import { getOnboardingTableStepTitle } from 'src/utils/collectedData'

interface Props {
  failedAt?: string | null
  failedSteps: string[]
  children: ReactNode
}

export const OnboardingFailureReasonTooltip = ({ failedAt, failedSteps, children }: Props) => {
  const text = useMemo(
    () =>
      `Failed ${failedAt ? `at ${dayjs(failedAt).format('MMM D, h:mm A')} ` : ''}due to ${
        failedSteps.length > 1 ? 'multiple' : 'one of the'
      } automated checks failing${failedSteps.length ? ':' : ''}`,
    [failedAt, failedSteps],
  )

  return (
    <TitledTooltip
      title="Onboarding failed"
      content={
        <>
          <Typography variant="body2" color={colors.SHADES_GREY_300}>
            {text}
          </Typography>
          {!!failedSteps.length && (
            <div css={tw`mt-2`}>
              <BulletList
                variant="body2"
                color={colors.SHADES_GREY_300}
                texts={failedSteps.map(getOnboardingTableStepTitle)}
              />
            </div>
          )}
        </>
      }
    >
      {children}
    </TitledTooltip>
  )
}
