import { memo, useEffect, useState } from 'react'
import tw from 'twin.macro'
import { CircleCancelMajor, ClockMajor, CustomerPlusMajor } from '@shopify/polaris-icons'
import { OnboardingTab } from './OnboardingTab'
import { useSelector } from 'react-redux'
import { getOnboardingCounts } from 'src/selectors/onboarding'
import { OnboardingStatus } from 'src/constants/onboarding/enums'
import { OnboardingCounts } from 'src/interfaces/onboarding'
import { colors } from 'src/theme'
import { addS } from 'src/utils/baseUtils'

const tabs = [
  {
    type: OnboardingStatus.Created,
    title: 'Invited contractors',
    renderIcon: (selected: boolean) => (
      <CustomerPlusMajor
        width={20}
        height={20}
        fill={selected ? colors.PRIMARY_PURPLE_600 : colors.GRAY_BLACK}
      />
    ),
  },
  {
    type: OnboardingStatus.WaitingForApproval,
    title: 'Waiting for approval',
    renderIcon: (selected: boolean) => (
      <ClockMajor
        width={20}
        height={20}
        fill={selected ? colors.PRIMARY_PURPLE_600 : colors.GRAY_BLACK}
      />
    ),
  },
  {
    type: OnboardingStatus.Failed,
    title: 'Onboarding failed',
    renderIcon: (selected: boolean) => (
      <CircleCancelMajor
        width={20}
        height={20}
        fill={selected ? colors.PRIMARY_PURPLE_600 : colors.GRAY_BLACK}
      />
    ),
  },
]

interface Props {
  selectedTab: OnboardingStatus
  onClick(tab: OnboardingStatus): void
}

const OnboardingTabsComponent = ({ selectedTab, onClick }: Props) => {
  const onboardingCounts = useSelector(getOnboardingCounts)
  const [counts, setCounts] = useState<Partial<OnboardingCounts>>()

  useEffect(() => {
    const {
      created,
      partially_completed: partiallyCompleted,
      ...otherCounts
    } = onboardingCounts || {}

    setCounts({
      ...otherCounts,
      [OnboardingStatus.Created]: (created || 0) + (partiallyCompleted || 0),
    })
  }, [onboardingCounts])

  return (
    <div css={tw`bg-white rounded-t-lg flex w-full overflow-hidden`}>
      {tabs.map((item) => (
        <OnboardingTab
          title={item.title}
          key={item.title}
          type={item.type}
          icon={item.renderIcon(item.type === selectedTab)}
          subtitle={
            counts ? `${counts[item.type] || 0} ${addS(counts[item.type] || 0, 'contractor')}` : ''
          }
          selected={item.type === selectedTab}
          onClick={onClick}
        />
      ))}
    </div>
  )
}

export const OnboardingTabs = memo(OnboardingTabsComponent)
