import { memo, useMemo } from 'react'
import tw from 'twin.macro'
import { ContractorDetails } from 'src/interfaces/contractor'
import { DateTime } from 'luxon'
import { addS } from 'src/utils/baseUtils'
import { CollectedDataStatus } from 'src/constants/collectedData'
import { colors } from 'src/theme'
import { Typography } from '@mui/material'

interface Props {
  contractorDetails: ContractorDetails
}

const ContractorPendingStepComponent = ({ contractorDetails }: Props) => {
  const lastUpdateIn = useMemo(() => {
    return Math.round(
      DateTime.now().diff(
        DateTime.fromMillis((contractorDetails.updatedAt || contractorDetails.createdAt) * 1000),
        'days',
      ).days,
    )
  }, [contractorDetails.createdAt, contractorDetails.updatedAt])

  return (
    <div css={tw`bg-white rounded-2xl mb-6 px-[24px] py-4 text-center`}>
      <div css={tw`grid grid-cols-3 gap-4 items-end`}>
        <div>
          <Typography color={colors.GRAY_COOL_GRAY} css={tw`mb-1`} variant="h6">
            Date of invitation
          </Typography>
          <Typography color="black" variant="body2">
            {DateTime.fromMillis(contractorDetails.createdAt * 1000).toFormat('LLL dd, yyyy')}
          </Typography>
        </div>
        <div>
          <Typography color={colors.GRAY_COOL_GRAY} css={tw`mb-1`} variant="h6">
            Last activity
          </Typography>
          <Typography color="black" variant="body2">
            {lastUpdateIn ? `${lastUpdateIn} ${addS(lastUpdateIn, 'day')} ago` : 'Today'}
          </Typography>
        </div>
        <div>
          <Typography color={colors.GRAY_COOL_GRAY} css={tw`mb-1 `} variant="h6">
            Steps completed
          </Typography>
          <Typography color="black" variant="body2">
            {`${
              contractorDetails.collectedData.filter(
                (item) => item.status !== CollectedDataStatus.Created,
              ).length
            } of ${contractorDetails.collectedData.length}`}
          </Typography>
        </div>
      </div>
    </div>
  )
}

export const ContractorPendingStep = memo(ContractorPendingStepComponent)
