import React, { memo, useMemo } from 'react'
import tw from 'twin.macro'
import { Typography } from '@mui/material'
import dayjs from 'dayjs'
import { ContractorActivitiesLog } from 'src/interfaces/contractor'
import { colors } from 'src/theme'
import { formatActivityLogMessage } from './utils'

interface ActivityDataRowProps {
  activityLog: ContractorActivitiesLog
}

const ActivityLogItemComponent = ({ activityLog }: ActivityDataRowProps) => {
  const dateFormatted = useMemo(
    () => dayjs.unix(activityLog.createdAt).format('hh:mm A MM/DD/YY'),
    [activityLog.createdAt],
  )

  const message = useMemo(() => formatActivityLogMessage(activityLog), [activityLog])

  return (
    <div
      css={tw`flex flex-col border-0 border-b border-solid border-[#EDEDED] py-3 first:pt-0 last:pb-0 last:border-b-0 gap-1`}
    >
      <div css={tw`flex items-center justify-between`}>
        <Typography variant="h6" css={tw`font-semibold`} color={colors.SHADES_GREY_400}>
          {activityLog.title}
        </Typography>
        <Typography variant="subtitle2" color={colors.SHADES_GREY_300}>
          {dateFormatted}
        </Typography>
      </div>
      <Typography variant="body2">{message}</Typography>
    </div>
  )
}

export const ActivityLogItem = memo(ActivityLogItemComponent)
