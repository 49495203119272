import React, { memo, useCallback, useEffect, useMemo, useRef, useState } from 'react'
import tw from 'twin.macro'
import { useDispatch, useSelector } from 'react-redux'
import { FetchContractorsCounts } from 'src/constants/actionTypes'
import { getSelectedMarket } from 'src/selectors/market'
import { Typography } from '@mui/material'
import { ContractorState } from 'src/constants/contractor/enums'
import { ManageTagsButton } from 'src/components/tags/ManageTagsButton'
import { ContractorTabs } from '../tabs/ContractorTabs'
import { useLocation } from 'react-router-dom'
import { ContractorsTable } from '../contractors-table/ContractorsTable'
import { ContractorsContext, IContractorsContext } from './Contractors.context'

const ContractorsComponent = () => {
  const dispatch = useDispatch()
  const { state } = useLocation()

  const selectedMarket = useSelector(getSelectedMarket)

  const selectedTabRef = useRef<ContractorState | null>(null)

  const [selectedTab, setSelectedTab] = useState(ContractorState.Active)
  const [isManageTagsOpen, setIsManageTagsOpen] = useState(false)
  const [searchQuery, setSearchQuery] = useState<string>('')

  const handleChangeTab = useCallback((tab: ContractorState) => {
    selectedTabRef.current = tab
    setSelectedTab(tab)
  }, [])

  useEffect(() => {
    if (!state?.selectedTab || state?.selectedTab === selectedTabRef.current) {
      return
    }

    handleChangeTab(state?.selectedTab)
  }, [state?.selectedTab])

  const fetchContractorsCounts = useCallback(() => {
    const params: any = {}

    if (selectedMarket?.id) {
      params.partnerMarketId = selectedMarket?.id
    }

    dispatch(FetchContractorsCounts.request(params))
  }, [selectedMarket?.id])

  useEffect(() => {
    fetchContractorsCounts()
  }, [fetchContractorsCounts])

  const openManageTags = useCallback(() => {
    setIsManageTagsOpen(true)
  }, [])

  const closeManageTags = useCallback(() => {
    setIsManageTagsOpen(false)
  }, [])

  const contractorsContextValue = useMemo<IContractorsContext>(() => {
    return {
      searchQuery,
      setSearchQuery,
    }
  }, [searchQuery, setSearchQuery])

  return (
    <ContractorsContext.Provider value={contractorsContextValue}>
      <div>
        <div css={tw`flex flex-row items-center justify-between mb-6`}>
          <Typography variant="h2" css={tw`mb-2`}>
            Contractors
          </Typography>
          <div>
            <ManageTagsButton
              isManageTagsOpen={isManageTagsOpen}
              openManageTags={openManageTags}
              closeManageTags={closeManageTags}
            />
          </div>
        </div>
        <ContractorTabs selectedTab={selectedTab} onClick={handleChangeTab} />
        {selectedTab === ContractorState.Active && (
          <ContractorsTable
            selectedState={ContractorState.Active}
            openManageTags={openManageTags}
          />
        )}
        {selectedTab === ContractorState.Deactivated && (
          <ContractorsTable
            selectedState={ContractorState.Deactivated}
            openManageTags={openManageTags}
          />
        )}
        {selectedTab === ContractorState.Recollection && (
          <ContractorsTable
            selectedState={ContractorState.Recollection}
            openManageTags={openManageTags}
          />
        )}
      </div>
    </ContractorsContext.Provider>
  )
}

export const Contractors = memo(ContractorsComponent)
