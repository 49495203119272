import React, { memo, useCallback, useEffect, useMemo, useRef, useState } from 'react'
import tw from 'twin.macro'
import { PaymentsGrid } from './PaymentsGrid'
import { useDispatch, useSelector } from 'react-redux'
import { Payment } from 'src/interfaces/payment'
import { createAsyncAction } from 'src/utils/reduxUtils'
import { FetchPayments } from 'src/constants/actionTypes'
import { paymentPageSizeOptions } from 'src/constants/payment/paymentGrid'
import { IPaymentsContext, PaymentsContext } from './Payments.context'
import { GridPaginationModel, GridRowSelectionModel, GridSortModel } from '@mui/x-data-grid-pro'
import { useGridPagination } from 'src/utils/hooks/grid/useGridPagination'
import { getPartnerId } from 'src/selectors/user'
import { getSelectedMarket } from 'src/selectors/market'
import { PaymentsHeader } from './PaymentsHeader'
import { Typography } from '@mui/material'
import { PaymentsItemActionMenu } from './action/PaymentsItemActionMenu'
import { Button } from 'src/components/shared/Button'
import { NoteMinor } from '@shopify/polaris-icons'
import { PaymentStatus } from 'src/constants/payment/enums'
import { ProcessPaymentsButton } from '../ProcessPaymentsButton'
import { ProtectedWithPermission } from 'src/components/shared/ProtectedWithPermission'
import { UserPermission } from 'src/constants/user'
import { handleApiError } from 'src/utils/errorHandlers'
import { AddPaymentsActionsButton } from '../add-payments/AddPaymentsActionsButton'
import { useLocation } from 'react-router-dom'
import _ from 'lodash'
import { Pagination } from 'src/interfaces/table'

interface FetchPaymentsParams {
  limit?: number
  offset?: number
  searchQuery?: string
  tagsFilter?: Array<string>
  sortModel?: GridSortModel
}

const PaymentsComponent = () => {
  const dispatch = useDispatch()
  const { state } = useLocation()

  const stateFilterRef = useRef<Array<PaymentStatus>>([])

  const partnerId = useSelector(getPartnerId)
  const selectedMarket = useSelector(getSelectedMarket)

  const { paginationModel, limit, offset, setPaginationModel } =
    useGridPagination(paymentPageSizeOptions)

  const [isLoading, setIsLoading] = useState<boolean>(false)
  const [_payments, setPayments] = useState<Array<Payment>>([])
  const [totalPayments, setTotalPayments] = useState<number>(0)
  const [actionAnchor, setActionAnchor] = useState<null | HTMLElement>(null)
  const [paymentToAction, setPaymentToAction] = useState<Payment | null>(null)
  const [selectedRows, setSelectedRows] = useState<GridRowSelectionModel>([])
  const [searchQuery, setSearchQuery] = useState<string>('')
  const [statusFilter, setStatusFilter] = useState<Array<PaymentStatus>>(state?.statusFilter || [])
  const [sortModel, setSortModel] = useState<GridSortModel>([{ field: 'uploadedAt', sort: 'desc' }])

  const payments = useMemo(() => _.uniqBy(_payments, 'id'), [_payments])

  useEffect(() => {
    if (!state?.statusFilter || _.isEqual(state?.statusFilter, stateFilterRef.current)) {
      return
    }

    stateFilterRef.current = state?.statusFilter
    setStatusFilter(state?.statusFilter)
  }, [state?.statusFilter])

  const notPaidPayments = useMemo(
    () => payments.filter((item) => item.status === PaymentStatus.NotPaid),
    [payments],
  )

  const selectedPayments = useMemo<Array<Payment>>(() => {
    return selectedRows.reduce<Array<Payment>>((arr, selectedRowId) => {
      const payment = payments.find((item) => item.id === selectedRowId)

      if (payment) {
        arr.push(payment)
      }

      return arr
    }, [])
  }, [payments, selectedRows])

  const fetchPayments = useCallback(
    async ({ limit, offset, searchQuery, sortModel }: FetchPaymentsParams) => {
      if (!partnerId) {
        return
      }

      setIsLoading(true)

      try {
        const params: any = {
          partnerId,
          limit,
          offset,
          paymentStatus: statusFilter,
        }

        if (searchQuery) {
          params.search = searchQuery
        }

        if (selectedMarket?.id) {
          params.partnerMarketId = selectedMarket?.id
        }

        if (sortModel && sortModel[0]) {
          params.sort = `${sortModel[0].field}:${sortModel[0].sort?.toUpperCase()}`
        }

        const payload: { data: Array<Payment>; pagination: Pagination } = await createAsyncAction(
          dispatch,
          FetchPayments.request(params),
        )

        setPayments(payload.data)
        setTotalPayments(payload.pagination.total)
      } catch (err: unknown) {
        handleApiError(err)
      }

      setIsLoading(false)
    },
    [partnerId, statusFilter, selectedMarket?.id],
  )

  const refreshPayments = useCallback(async () => {
    await fetchPayments({ limit, offset, searchQuery, sortModel })
  }, [limit, offset, searchQuery, sortModel, fetchPayments])

  useEffect(() => {
    fetchPayments({
      limit,
      offset,
      searchQuery,
      sortModel,
    })
  }, [limit, offset, searchQuery, partnerId, sortModel, statusFilter, selectedMarket?.id])

  const handleChangePagination = useCallback((paginationModel: GridPaginationModel) => {
    setPaginationModel(paginationModel)
  }, [])

  const openPaymentsItemAction = useCallback(
    (event: React.MouseEvent<HTMLButtonElement>, paymentToEdit: Payment) => {
      setActionAnchor(event.currentTarget)

      setPaymentToAction(paymentToEdit)
    },
    [],
  )

  const closePaymentsItemAction = useCallback(() => {
    setActionAnchor(null)
  }, [])

  const clearPaymentsItemAction = useCallback(() => {
    setActionAnchor(null)
    setPaymentToAction(null)
  }, [])

  const handleSelectStatus = useCallback((statuses: Array<PaymentStatus>) => {
    setStatusFilter(statuses.slice(statuses.length - 1, statuses.length))
    setSelectedRows([])
  }, [])

  const handleSortModelChange = (newModel: GridSortModel) => {
    setSortModel(newModel)
  }

  const paymentsContextValue = useMemo<IPaymentsContext>(() => {
    return {
      selectedRows,
      setSelectedRows,

      selectedPayments,

      searchQuery,
      setSearchQuery,

      statusFilter,
      setStatusFilter: handleSelectStatus,

      refreshPayments,

      actionAnchor,
      paymentToAction,
      openPaymentsItemAction,
      closePaymentsItemAction,
      clearPaymentsItemAction,
    }
  }, [
    selectedRows,
    setSelectedRows,

    selectedPayments,

    searchQuery,
    setSearchQuery,

    statusFilter,
    handleSelectStatus,

    refreshPayments,

    actionAnchor,
    paymentToAction,
    openPaymentsItemAction,
    closePaymentsItemAction,
    clearPaymentsItemAction,
  ])

  return (
    <PaymentsContext.Provider value={paymentsContextValue}>
      <div css={tw`flex flex-row items-center justify-between mb-6`}>
        <Typography variant="h2" css={tw`mb-2`}>
          Payments
        </Typography>
        <div>
          <a href="/payments_example.csv" download="payments_example.csv">
            <Button
              className={tw`mr-4`}
              size="small"
              color="white"
              rightIcon={<NoteMinor width={20} height={20} />}
            >
              Download CSV template
            </Button>
          </a>
          <ProtectedWithPermission permission={UserPermission.PaymentsCreate}>
            <AddPaymentsActionsButton />
          </ProtectedWithPermission>
          <ProtectedWithPermission permission={UserPermission.PaymentsProcess}>
            <ProcessPaymentsButton notPaidPaymentsAmount={notPaidPayments.length} />
          </ProtectedWithPermission>
        </div>
      </div>
      <PaymentsHeader />
      <PaymentsGrid
        payments={payments}
        isLoading={isLoading}
        paginationModel={paginationModel}
        pageSizeOptions={paymentPageSizeOptions}
        rowCount={totalPayments}
        rowSelectionModel={selectedRows}
        sortModel={sortModel}
        onSortModelChange={handleSortModelChange}
        onPaginationModelChange={handleChangePagination}
        onRowSelectionModelChange={setSelectedRows}
      />
      <PaymentsItemActionMenu />
    </PaymentsContext.Provider>
  )
}

export const Payments = memo(PaymentsComponent)
