import { collectedDataStatusTitle, collectedDataStepsTitle } from 'src/constants/collectedData'
import { failureReasonTitles } from 'src/constants/payment/constants'
import {
  CollectDataStepContractorActivitiesLog,
  CollectDataStepUpdatedContractorActivitiesLog,
  ContractorActivitiesLog,
  PaymentFailedContractorActivitiesLog,
} from 'src/interfaces/contractor'

const collectedDataStepActivityLogsTypes = [
  'collect_data_step_started',
  'collect_data_step_submitted',
  'collect_data_step_auto_approved',
  'collect_data_step_approved',
  'collect_data_step_declined',
]

const activityLogIsCollectDataStep = (
  activityLog: ContractorActivitiesLog,
): activityLog is CollectDataStepContractorActivitiesLog =>
  collectedDataStepActivityLogsTypes.includes(activityLog.type)

const activityLogIsCollectDataStepUpdated = (
  activityLog: ContractorActivitiesLog,
): activityLog is CollectDataStepUpdatedContractorActivitiesLog =>
  activityLog.type === 'collect_data_step_updated'

const activityLogIsPaymentFailed = (
  activityLog: ContractorActivitiesLog,
): activityLog is PaymentFailedContractorActivitiesLog => activityLog.type === 'payment_failed'

export const formatActivityLogMessage = (activityLog: ContractorActivitiesLog): string => {
  if (activityLogIsPaymentFailed(activityLog)) {
    return activityLog.description.replace(
      activityLog.metadata.failureReasonType,
      `"${
        failureReasonTitles[activityLog.metadata.failureReasonType] ||
        activityLog.metadata.failureReasonType
      }"`,
    )
  }

  if (activityLogIsCollectDataStep(activityLog)) {
    return activityLog.description.replace(
      activityLog.metadata.collectDataStepLabel,
      `"${
        collectedDataStepsTitle[activityLog.metadata.collectDataStepLabel] ||
        activityLog.metadata.collectDataStepLabel
      }"`,
    )
  }

  if (activityLogIsCollectDataStepUpdated(activityLog)) {
    return activityLog.description
      .replace(
        activityLog.metadata.collectDataStepLabel,
        `"${
          collectedDataStepsTitle[activityLog.metadata.collectDataStepLabel] ||
          activityLog.metadata.collectDataStepLabel
        }"`,
      )
      .replace(
        activityLog.metadata.newStatus,
        `"${
          collectedDataStatusTitle[activityLog.metadata.newStatus] || activityLog.metadata.newStatus
        }"`,
      )
  }

  return activityLog.description
}
