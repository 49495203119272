import React, { memo, useMemo, useState } from 'react'
import tw from 'twin.macro'
import { ContractorDetails } from 'src/interfaces/contractor'
import { ContractorStepItem } from './ContractorStepItem'
import { CollectedDataStatus } from 'src/constants/collectedData'
import { OnboardingStatus } from 'src/constants/onboarding/enums'

interface Props {
  contractorDetails: ContractorDetails
  filterByStatus?: CollectedDataStatus
  onRefresh: () => void | Promise<void>
}

const ONBOARDING_STATUSES_WITH_DELAYED_INFO = [
  OnboardingStatus.Created,
  OnboardingStatus.WaitingForApproval,
  OnboardingStatus.Failed,
  OnboardingStatus.PartiallyCompleted,
]

const ContractorStepsComponent = ({ contractorDetails, filterByStatus, onRefresh }: Props) => {
  const [openModalId, setOpenModalId] = useState<string | null>(null)

  const steps = useMemo(
    () =>
      contractorDetails.collectedData
        .filter((item) => !filterByStatus || item.status === filterByStatus)
        // sort delayed last
        .sort((x, y) => Number(x.collectDataStep.delayed) - Number(y.collectDataStep.delayed)),
    [contractorDetails.collectedData],
  )

  const firstDelayedStepIndex = useMemo(
    () => steps.findIndex((item) => !!item.collectDataStep.delayed),
    [steps],
  )

  return (
    <div css={tw`flex flex-col gap-2`}>
      {steps.map((item, index) => (
        <ContractorStepItem
          key={item.id}
          collectedData={item}
          contractorDetails={contractorDetails}
          onRefreshContractors={onRefresh}
          delayed={
            ONBOARDING_STATUSES_WITH_DELAYED_INFO.includes(contractorDetails?.onboarding?.status) &&
            firstDelayedStepIndex === index
          }
          modalId={item.id}
          openModalId={openModalId}
          setOpenModalId={setOpenModalId}
        />
      ))}
    </div>
  )
}

export const ContractorSteps = memo(ContractorStepsComponent)
