import React, { ReactNode } from 'react'
import { DataRow } from 'src/components/shared/DataRow'
import { DateTime } from 'luxon'
import { vehicleNamesMap } from 'src/constants/vehicle'

import {
  CollectedData,
  FormCollectedData,
  InsuranceBeneficiaryCollectedData,
  TrainingCollectedData,
  VaccinationInformationCollectedData,
  BusinessEntityCollectedData,
} from 'src/interfaces/collectedData'
import {
  backgroundCheckDataStatusTitles,
  CollectDataFormItemType,
  CollectedDataStatus,
  CollectedDataStepType,
  getBackgroundCheckReminderText,
  // getStitchVendorReminderText,
  // stitchVendorStatusTitles,
} from 'src/constants/collectedData'
import { ContractorDetails } from 'src/interfaces/contractor'
import tw from 'twin.macro'
import { InfoContainer } from '../shared/InfoContainer'

interface Props {
  collectedData: CollectedData
  contractorDetails: ContractorDetails
  header?: ReactNode
}

const stepStatusesToShowReportStatus = [CollectedDataStatus.InProgress, CollectedDataStatus.Created]

const isTrainingDocument = (collectedData: CollectedData): collectedData is TrainingCollectedData =>
  collectedData.collectDataStep.type === CollectedDataStepType.Training

const isBusinessEntityDocument = (
  collectedData: CollectedData,
): collectedData is BusinessEntityCollectedData =>
  collectedData.collectDataStep.type === CollectedDataStepType.BusinessEntity

const isInsuranceBeneficiaryDocument = (
  collectedData: CollectedData,
): collectedData is InsuranceBeneficiaryCollectedData =>
  collectedData.collectDataStep.type === CollectedDataStepType.InsuranceBeneficiary

const isVaccinationInformationDocument = (
  collectedData: CollectedData,
): collectedData is VaccinationInformationCollectedData =>
  collectedData.collectDataStep.type === CollectedDataStepType.VaccinationInformation

const isFormDocument = (collectedData: CollectedData): collectedData is FormCollectedData =>
  collectedData.collectDataStep.type === CollectedDataStepType.Form

export const getContractorStepData = ({ collectedData, contractorDetails }: Props) => {
  if (isTrainingDocument(collectedData)) {
    const dateSubmitted = collectedData.finishedAt

    return (
      <div>
        <DataRow
          title="Date submitted"
          text={dateSubmitted ? DateTime.fromISO(dateSubmitted).toFormat('LLL dd, yyyy') : '-'}
        />
      </div>
    )
  }

  if (isInsuranceBeneficiaryDocument(collectedData)) {
    if (!collectedData.metadata?.firstName && !collectedData.metadata?.lastName) {
      return null
    }

    return (
      <div>
        <DataRow title="First name" text={collectedData.metadata?.firstName} />
        <DataRow title="Last name" text={collectedData.metadata?.lastName} />
      </div>
    )
  }

  if (isVaccinationInformationDocument(collectedData)) {
    const isVaccinated = collectedData.metadata?.isVaccinated

    return (
      <div>
        <DataRow title="Vaccinated" text={isVaccinated ? 'YES' : 'NO'} />
        {isVaccinated && (
          <div>
            <DataRow title="Date of most recent vaccination" text={collectedData.metadata?.date} />
            <DataRow title="Vaccine brand you received" text={collectedData.metadata?.brand} />
          </div>
        )}
      </div>
    )
  }

  if (collectedData.collectDataStep.type === CollectedDataStepType.VehicleAndInsurance) {
    return (
      <div>
        <DataRow
          title="Vehicle type"
          text={contractorDetails.type ? vehicleNamesMap[contractorDetails.type] : '-'}
        />
        {!!contractorDetails.year && <DataRow title="Vehicle year" text={contractorDetails.year} />}
        {!!contractorDetails.carMake && <DataRow title="Make" text={contractorDetails.carMake} />}
        {!!contractorDetails.carModel && (
          <DataRow title="Model" text={contractorDetails.carModel} />
        )}
        {!!contractorDetails.vin && <DataRow title="VIN" text={contractorDetails.vin} />}
      </div>
    )
  }

  if (collectedData.collectDataStep.type === CollectedDataStepType.BackgroundCheck) {
    if (
      !collectedData.dataStatus ||
      !stepStatusesToShowReportStatus.includes(collectedData.status)
    ) {
      return null
    }

    const reminderText = getBackgroundCheckReminderText(
      collectedData.dataStatus,
      collectedData.status,
    )

    return (
      <>
        <DataRow
          title="Yardstik report status"
          text={backgroundCheckDataStatusTitles[collectedData.dataStatus]}
        />
        {reminderText && (
          <div css={[tw`py-3`]}>
            <InfoContainer>{reminderText}</InfoContainer>
          </div>
        )}
      </>
    )
  }

  // if (collectedData.collectDataStep.type === CollectedDataStepType.StitchPayment) {
  //   if (!stepStatusesToShowReportStatus.includes(collectedData.status)) {
  //     return null
  //   }

  //   const reminderText = getStitchVendorReminderText(
  //     collectedData.status,
  //     contractorDetails.stitchVendorState,
  //   )

  //   return (
  //     <>
  //       <DataRow
  //         title="Stitch vendor status"
  //         text={
  //           contractorDetails.stitchVendorState
  //             ? stitchVendorStatusTitles[contractorDetails.stitchVendorState]
  //             : 'Not started'
  //         }
  //       />
  //       {reminderText && (
  //         <div css={[tw`py-3`]}>
  //           <InfoContainer>{reminderText}</InfoContainer>
  //         </div>
  //       )}
  //     </>
  //   )
  // }

  if (isFormDocument(collectedData)) {
    if (!collectedData?.metadata?.formItems) {
      return null
    }

    return (
      <div>
        {collectedData?.metadata?.formItems.map((item) => {
          let text: string | number = ''

          if (
            item.type === CollectDataFormItemType.File ||
            item.type === CollectDataFormItemType.Video
          ) {
            return null
          }

          if (
            item.type === CollectDataFormItemType.Radio ||
            item.type === CollectDataFormItemType.Select
          ) {
            text = item.value.title
          }

          if (item.type === CollectDataFormItemType.Checkbox) {
            text = item.value.map((val) => val.title).join(', ')
          }

          if (
            item.type === CollectDataFormItemType.Number ||
            item.type === CollectDataFormItemType.TextInput ||
            item.type === CollectDataFormItemType.Date
          ) {
            text = item.value
          }

          return <DataRow direction="column" key={item.id} title={item.title} text={text} />
        })}
      </div>
    )
  }

  if (isBusinessEntityDocument(collectedData)) {
    return (
      <div>
        <DataRow title="Business entity" text={collectedData.metadata ? 'Yes' : 'No'} />
        {collectedData.metadata?.businessName ? (
          <DataRow title="Business name" text={collectedData.metadata?.businessName} />
        ) : null}
        {collectedData.metadata?.streetAddress ? (
          <DataRow title="Street address" text={collectedData.metadata?.streetAddress} />
        ) : null}
        {collectedData.metadata?.city ? (
          <DataRow title="City" text={collectedData.metadata?.city} />
        ) : null}
        {collectedData.metadata?.state ? (
          <DataRow title="State" text={collectedData.metadata?.state} />
        ) : null}
        {collectedData.metadata?.zipCode ? (
          <DataRow title="Zip code" text={collectedData.metadata?.zipCode} />
        ) : null}
      </div>
    )
  }

  return null
}
