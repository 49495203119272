import { memo } from 'react'
import { Chip } from 'src/components/shared/Chip'
import { PaymentStatus } from 'src/constants/payment/enums'
import { colors } from 'src/theme'
import tw from 'twin.macro'

interface Props {
  status: PaymentStatus
}

export const paymentsTitles = {
  [PaymentStatus.Paid]: 'Paid',
  [PaymentStatus.NotPaid]: 'Not Paid',
  [PaymentStatus.Pending]: 'Pending',
  [PaymentStatus.AutoRetry]: 'Auto-retry',
  [PaymentStatus.Failed]: 'Failed',
}

const paymentStatusMap = {
  [PaymentStatus.Paid]: {
    color: colors.COLORS_GREEN_500,
    text: paymentsTitles[PaymentStatus.Paid],
    bgStyle: tw`bg-[rgba(230, 249, 233, 1)]`,
  },
  [PaymentStatus.NotPaid]: {
    color: colors.COLORS_BLUE_500,
    text: paymentsTitles[PaymentStatus.NotPaid],
    bgStyle: tw`bg-[rgba(216, 229, 255, 1)]`,
  },
  [PaymentStatus.Pending]: {
    color: colors.COLORS_ORANGE_500,
    text: paymentsTitles[PaymentStatus.Pending],
    bgStyle: tw`bg-[rgba(255, 238, 214, 1)]`,
  },
  [PaymentStatus.AutoRetry]: {
    color: colors.COLORS_ORANGE_500,
    text: paymentsTitles[PaymentStatus.AutoRetry],
    bgStyle: tw`bg-[rgba(255, 238, 214, 1)]`,
  },
  [PaymentStatus.Failed]: {
    color: colors.COLORS_RED_500,
    text: paymentsTitles[PaymentStatus.Failed],
    bgStyle: tw`bg-[rgba(255, 232, 238, 1)]`,
  },
}

const getPaymentStatusParams = (status: PaymentStatus) => {
  return (
    paymentStatusMap[status] || {
      color: colors.SHADES_GREY_500,
      text: status,
      bgStyle: tw`bg-[rgba(218, 217, 224, 1)]`,
    }
  )
}

const PaymentStatusChipComponent = ({ status }: Props) => {
  const params = getPaymentStatusParams(status)

  return <Chip className={params.bgStyle} textColor={params.color} text={params.text} />
}

export const PaymentStatusChip = memo(PaymentStatusChipComponent)
