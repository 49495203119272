import React, { memo, useCallback } from 'react'
import { Typography } from '@mui/material'
import tw from 'twin.macro'
import { DataRow } from 'src/components/shared/DataRow'
import { ContractorDetails } from 'src/interfaces/contractor'
import { useDispatch, useSelector } from 'react-redux'
import { createAsyncAction } from 'src/utils/reduxUtils'
import { UpdatePartnerDriver } from 'src/constants/actionTypes'
import { showToast } from 'src/utils/toast'
import { handleApiError } from 'src/utils/errorHandlers'
import { ContractorOnboardingStatusChip } from '../ContractorOnboardingStatusChip'
import { getContractorStatus } from 'src/utils/contractor'
import { OnboardingStatus } from 'src/constants/onboarding/enums'
import { getAccessInfo } from 'src/selectors/user'
import { UserProductName } from 'src/constants/user'
import { ContractorCXTStatusChip } from '../ContractorCXTStatusChip'

interface Props {
  contractorDetails: ContractorDetails
  onboardingStatus: OnboardingStatus
  onboardingFailedAt?: string | null
  onboardingFailedSteps?: string[]
  onboardingExpiredSteps?: string[]
  flow: 'onboardings' | 'contractors'
  onUpdate(): void | Promise<void>
}

const ContractorPartnerDriverInfoComponent = ({
  contractorDetails,
  onboardingStatus,
  onboardingFailedAt,
  onboardingFailedSteps,
  onboardingExpiredSteps,
  flow,
  onUpdate,
}: Props) => {
  const dispatch = useDispatch()

  const accessInfo = useSelector(getAccessInfo)

  const handleEditExternalId = useCallback(
    async (externalId: string) => {
      if (externalId === contractorDetails.externalId) {
        return
      }

      try {
        await createAsyncAction(
          dispatch,
          UpdatePartnerDriver.request({ partnerDriverId: contractorDetails.id, externalId }),
        )

        await onUpdate()

        showToast('Contractor ID saved.')
      } catch (err: any) {
        handleApiError(err)
      }
    },
    [contractorDetails, onUpdate],
  )

  return (
    <div css={tw`flex p-4`}>
      <div css={tw`w-full`}>
        <div>
          <Typography variant="h2">{`${contractorDetails.firstName} ${contractorDetails.lastName}`}</Typography>
          {contractorDetails.id && <DataRow className={tw`pt-1`} title={contractorDetails.id} />}
        </div>
        <DataRow
          title="Contractor status"
          direction="row"
          component={
            <ContractorOnboardingStatusChip
              contractorStatus={getContractorStatus(contractorDetails)}
              deactivationReason={contractorDetails.deactivationReason}
              deactivatedAt={contractorDetails.deactivatedAt}
              deactivatedByEmail={contractorDetails.deactivatedByEmail}
              onboardingStatus={onboardingStatus}
              failedAt={onboardingFailedAt}
              failedSteps={onboardingFailedSteps}
              expiredSteps={onboardingExpiredSteps}
            />
          }
        />
        {flow === 'contractors' &&
          accessInfo?.products?.some((item) => item.product === UserProductName.CXTIntegration) && (
            <DataRow
              title="CXT"
              direction="row"
              component={<ContractorCXTStatusChip cxtSynced={contractorDetails?.cxtSynced} />}
            />
          )}
        <DataRow
          title="External ID"
          text={contractorDetails.externalId}
          value={contractorDetails.externalId}
          onEdit={handleEditExternalId}
          showCopyButton
        />
        {contractorDetails?.businessInfo?.businessName && (
          <DataRow
            direction="column"
            title="Business name"
            text={contractorDetails.businessInfo.businessName}
          />
        )}
        {contractorDetails.phone && (
          <DataRow direction="row" title="Phone number" text={contractorDetails.phone} />
        )}
        {contractorDetails.email && (
          <DataRow direction="row" title="Email" text={contractorDetails.email} />
        )}
      </div>
    </div>
  )
}

export const ContractorPartnerDriverInfo = memo(ContractorPartnerDriverInfoComponent)
