import React, { memo } from 'react'
import tw from 'twin.macro'
import { Typography } from '@mui/material'
import { CollectedDataStatus } from 'src/constants/collectedData'
import { OnboardingStatus } from 'src/constants/onboarding/enums'
import { ContractorDetails } from 'src/interfaces/contractor'
import { ContractorFailedStep } from '../ContractorFailedStep'
import { ContractorPendingStep } from '../ContractorPendingStep'
import { ContractorSteps } from '../ContractorSteps'
import { colors } from 'src/theme'

interface OnboardingStepsTabProps {
  contractorDetails: ContractorDetails
  onRefresh(): Promise<void>
  flow: 'onboardings' | 'contractors'
}

const OnboardingStepsTabComponent = memo(
  ({ contractorDetails, onRefresh, flow }: OnboardingStepsTabProps) => {
    const hasStepsWithRecollectingStatus = contractorDetails.collectedData.some(
      (item) => !!item.isRecollecting,
    )

    return (
      <div css={tw`flex flex-1 flex-col overflow-y-scroll h-full bg-[rgba(246, 246, 246, 1)]`}>
        <div css={tw`p-4 overflow-y-scroll pb-20`}>
          {(contractorDetails.onboarding.status === OnboardingStatus.Created ||
            contractorDetails.onboarding.status === OnboardingStatus.PartiallyCompleted) && (
            <div>
              <ContractorPendingStep contractorDetails={contractorDetails} />
              <ContractorSteps contractorDetails={contractorDetails} onRefresh={onRefresh} />
            </div>
          )}

          {(!contractorDetails.onboarding.status ||
            contractorDetails.onboarding.status === OnboardingStatus.WaitingForApproval ||
            contractorDetails.onboarding.status === OnboardingStatus.Completed) && (
            <div>
              {hasStepsWithRecollectingStatus && flow === 'contractors' && (
                <div css={tw`rounded-2xl bg-white overflow-hidden mb-4`}>
                  <div css={tw`flex flex-col items-center px-4 py-3`}>
                    <Typography color={colors.GRAY_COOL_GRAY} css={tw`mb-1`} variant="subtitle1">
                      Recollection
                    </Typography>
                    <Typography color="black" variant="body2">
                      Pending
                    </Typography>
                  </div>
                </div>
              )}
              <ContractorSteps contractorDetails={contractorDetails} onRefresh={onRefresh} />
            </div>
          )}

          {contractorDetails.onboarding.status === OnboardingStatus.Failed && (
            <div>
              <ContractorFailedStep contractorDetails={contractorDetails} />
              <ContractorSteps
                contractorDetails={contractorDetails}
                filterByStatus={CollectedDataStatus.Failed}
                onRefresh={onRefresh}
              />
            </div>
          )}
        </div>
      </div>
    )
  },
)

export const OnboardingStepsTab = OnboardingStepsTabComponent
