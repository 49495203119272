import React, { memo, ReactNode, useCallback, useMemo, useState } from 'react'
import tw from 'twin.macro'
import { Modal, Tooltip, Typography } from '@mui/material'
import { colors } from 'src/theme'
import { ModalHeader } from './ModalHeader'
import { EditFieldForm } from './edit-field-form/EditFieldForm'
import { Button } from './Button'
import { EditMinor, DuplicateMinor } from '@shopify/polaris-icons'
import { BasicSelectOption } from './form/BasicSelect'
import { Dayjs } from 'dayjs'
import { showToast } from 'src/utils/toast'

interface Props {
  title: string
  text?: string | null | number
  component?: ReactNode
  className?: any
  editPlaceholder?: string
  direction?: 'column' | 'row'
  editFiledType?: 'input' | 'select' | 'date'
  minDate?: Dayjs
  minDateError?: string
  options?: Array<BasicSelectOption>
  value?: any
  isEditDisabled?: boolean
  disabledEditTooltip?: string
  showCopyButton?: boolean
  onEdit?(value: string): void | Promise<void>
}

const DataRowComponent = ({
  text,
  title,
  className,
  component,
  editPlaceholder,
  direction = 'row',
  editFiledType,
  value,
  options = [],
  minDate,
  minDateError,
  isEditDisabled,
  disabledEditTooltip,
  showCopyButton = false,
  onEdit,
}: Props) => {
  const [isModalOpen, setIsModalOpen] = useState(false)

  const container = useMemo(() => document.getElementById('root'), [])

  const handleOpenModal = useCallback(() => {
    setIsModalOpen(true)
  }, [])

  const handleCloseModal = useCallback(() => {
    setIsModalOpen(false)
  }, [])

  const handleSubmit = useCallback(
    async (value: string) => {
      if (onEdit) {
        await onEdit(value)
      }

      handleCloseModal()
    },
    [onEdit],
  )

  const handleCopy = useCallback(() => {
    if (value !== null && value !== undefined) {
      navigator.clipboard.writeText(value.toString())
      showToast(`${title} successfully copied!`)
    }
  }, [value, title])

  return (
    <div
      css={[
        tw`py-[14px] border-0 border-b border-solid border-[#EDEDED] flex items-center justify-between`,
        className,
      ]}
    >
      <div
        css={[
          tw`flex w-full flex-col gap-1.5`,
          direction === 'row' && !onEdit && tw`flex-row justify-between gap-4 items-center`,
          onEdit && tw`max-w-[204px]`,
        ]}
      >
        <Typography color={colors.SHADES_GREY_400} variant="h6">
          {title}
        </Typography>
        {component || (
          <Typography
            style={{ wordWrap: 'break-word' }}
            css={[direction === 'row' && !onEdit && tw`text-end`, tw`text-black`]}
            variant="body2"
          >
            {text}
          </Typography>
        )}
      </div>

      {(onEdit || showCopyButton) && (
        <div css={tw`flex gap-2 flex-row-reverse`}>
          {onEdit && (
            <Tooltip
              disableHoverListener={!isEditDisabled}
              title={disabledEditTooltip}
              color="black"
            >
              <span>
                <Button
                  color="grey"
                  size="small"
                  disabled={isEditDisabled}
                  leftIcon={<EditMinor width={20} />}
                  className={tw`min-w-[36px]`}
                  onClick={handleOpenModal}
                />
              </span>
            </Tooltip>
          )}
          {showCopyButton && (
            <Tooltip title="Copy" color="black">
              <span>
                <Button
                  color="grey"
                  size="small"
                  leftIcon={<DuplicateMinor width={20} />}
                  className={tw`min-w-[36px]`}
                  onClick={handleCopy}
                />
              </span>
            </Tooltip>
          )}
        </div>
      )}

      {onEdit && (
        <Modal
          container={container}
          open={Boolean(isModalOpen)}
          onClose={handleCloseModal}
          aria-labelledby="parent-modal-title"
          aria-describedby="parent-modal-description"
          css={tw`flex justify-center items-center backdrop-blur`}
        >
          <div css={tw`bg-white rounded-lg`}>
            <ModalHeader title={`Edit ${title}`} onClose={handleCloseModal} />
            <EditFieldForm
              label={title}
              editFiledType={editFiledType}
              minDate={minDate}
              minDateError={minDateError}
              initialValue={value}
              options={options}
              placeholder={editPlaceholder}
              onSubmit={handleSubmit}
              onClose={handleCloseModal}
            />
          </div>
        </Modal>
      )}
    </div>
  )
}

export const DataRow = memo(DataRowComponent)
