import React, { memo, useCallback } from 'react'
import tw from 'twin.macro'
import { DataRow } from 'src/components/shared/DataRow'
import { Tags } from 'src/components/tags/Tags'
import { Tag } from 'src/interfaces/tag'
import { useDispatch, useSelector } from 'react-redux'
import { createAsyncAction } from 'src/utils/reduxUtils'
import { DriverAddTag, DriverDeleteTag, UpdatePartnerDriver } from 'src/constants/actionTypes'
import { showToast } from 'src/utils/toast'
import { handleApiError } from 'src/utils/errorHandlers'
import { getMarkets } from 'src/selectors/market'
import { ContractorDetails } from 'src/interfaces/contractor'

interface AddOnsTabProps {
  contractorDetails: ContractorDetails
  onUpdate(): void | Promise<void>
}

const AddOnsTabComponent = ({ contractorDetails, onUpdate }: AddOnsTabProps) => {
  const dispatch = useDispatch()
  const markets = useSelector(getMarkets)

  const handleEditMarket = useCallback(
    async (partnerMarketId: string) => {
      if (partnerMarketId === contractorDetails.partnerMarket?.id) {
        return
      }

      try {
        await createAsyncAction(
          dispatch,
          UpdatePartnerDriver.request({ partnerDriverId: contractorDetails.id, partnerMarketId }),
        )

        await onUpdate()

        showToast('Market saved.')
      } catch (err: any) {
        handleApiError(err)
      }
    },
    [contractorDetails, onUpdate, dispatch],
  )

  const handleSelectTag = useCallback(
    async (newTag: Tag) => {
      try {
        await createAsyncAction(
          dispatch,
          DriverAddTag.request({
            tagId: newTag.id,
            partnerDriverId: contractorDetails.id,
          }),
        )

        await onUpdate()

        showToast('Tag is added.')
      } catch (err: any) {
        handleApiError(err)
      }
    },
    [contractorDetails, onUpdate, dispatch],
  )

  const handleRemoveTag = useCallback(
    async (removedTag: Tag) => {
      try {
        await createAsyncAction(
          dispatch,
          DriverDeleteTag.request({
            tagId: removedTag.id,
            partnerDriverId: contractorDetails.id,
          }),
        )

        await onUpdate()

        showToast('Tag is removed.')
      } catch (err: any) {
        handleApiError(err)
      }
    },
    [contractorDetails, onUpdate, dispatch],
  )

  return (
    <div css={tw`flex flex-1 flex-col p-4 overflow-y-scroll h-full bg-[rgba(246, 246, 246, 1)]`}>
      <div css={tw`bg-white rounded-2xl px-4 py-1 overflow-hidden`}>
        {contractorDetails?.collectDataTemplate?.name && (
          <DataRow
            direction="column"
            title="Assigned Template"
            text={contractorDetails.collectDataTemplate.name}
          />
        )}
        {contractorDetails?.partnerMarket && (
          <DataRow
            direction="column"
            title="Market"
            editFiledType="select"
            options={markets.map((item) => ({
              label: item.title,
              value: item.id,
            }))}
            text={contractorDetails.partnerMarket.title}
            value={contractorDetails.partnerMarket.id}
            onEdit={handleEditMarket}
          />
        )}
        {contractorDetails?.referral && (
          <DataRow
            direction="column"
            title="Referral contractor"
            text={contractorDetails.referral}
          />
        )}
        <Tags
          tags={contractorDetails.tags}
          className={tw`py-3`}
          onSelectTag={handleSelectTag}
          onRemoveTag={handleRemoveTag}
        />
      </div>
    </div>
  )
}

export const AddOnsTab = memo(AddOnsTabComponent)
