import { memo } from 'react'
import { Chip } from 'src/components/shared/Chip'
import { Tooltip } from '@mui/material'

interface Props {
  titles: Array<string>
  textClassName?: any
  className?: any
}

const StepsChipComponent = ({ titles, textClassName, className }: Props) => {
  if (!titles.length) {
    return null
  }

  if (titles.length > 1) {
    return (
      <Tooltip title={titles.join(', ')} placement="bottom-start">
        <span>
          <Chip
            text={`${titles.length} documents`}
            textClassName={textClassName}
            className={className}
          />
        </span>
      </Tooltip>
    )
  }

  return <Chip text={titles[0]} className={className} textClassName={textClassName} />
}

export const StepsChip = memo(StepsChipComponent)
